import React from 'react';
import ChallengeCard from 'app/components/ChallengeCard';
import LogConquestActivities from 'app/components/LogConquestActivities';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import EmptyState from 'app/components/EmptyState';
import DuelCard from 'app/components/DuelCard';

export default function Challenges({ challenges, isLoading }) {
  if (isLoading) return <Loader />;

  return (
    <div className="page">
      <div className="page__col">
        {/* {challenges && challenges.length === 0 && (
          <EmptyState
            type="data"
            text={t('emptyState.challengesText')}
          />
        )} */}
        <div className="tab__cards">
          {challenges &&
            challenges.map(item => (
              <ChallengeCard
                key={item.id}
                challenge={item}
              />
            ))}
          <DuelCard />
        </div>
      </div>

      <aside className="page__aside">
        <LogConquestActivities
          conquers={challenges?.filter(item => item.done)}
          isLoading={isLoading}
        />
      </aside>
    </div>
  );
}
