import { useEffect, useRef } from 'react';
import Name from './Name';
import Questions from './Questions';
import Result from './Result';
import styles from './styles.module.scss';
import Modal from 'app/components/Modal';
import QuestionCountdown from './QuestionCountdown';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function PlayerModal({ duel, setOpenPlayerModal, setNewDuelModal, openPlayerModal }) {
  const questionCountdownRef = useRef();

  const { setCurrentStep, setStart, currentStep, setShowQuestionCountDown, showQuestionCountDown, setChallengeType, taxonomies, setQuestionCountDownTimer, setGeneralCount } = useWeeklyChallenge();

  useEffect(() => {
    setChallengeType('duel');
  }, []);

  const handleCloseModal = () => {
    setGeneralCount(0);
    setOpenPlayerModal(false);
    setStart(false);
    setShowQuestionCountDown(false);
    setQuestionCountDownTimer(5);
    setTimeout(() => setCurrentStep(1), 300);
  };

  useEffect(() => {
    if (openPlayerModal && currentStep === 1) {
      questionCountdownRef.current = setTimeout(() => {
        setShowQuestionCountDown(true);
      }, 6000);
    }
    return () => {
      if (questionCountdownRef.current) {
        clearTimeout(questionCountdownRef.current);
      }
    };
  }, [openPlayerModal, taxonomies]);

  return (
    <Modal
      show={openPlayerModal}
      onClose={handleCloseModal}
      contentClassName={`modal__content--lg u-pl-0 u-pr-0 ${styles['duel-modal']}`}
    >
      <div className={styles['player-modal']}>
        {currentStep === 1 && <Name duel={duel} />}
        {currentStep === 2 && <Questions />}
        {currentStep === 3 && (
          <Result
            setOpenPlayerModal={setOpenPlayerModal}
            setNewDuelModal={setNewDuelModal}
          />
        )}
      </div>
      <QuestionCountdown
        showQuestionCountDown={showQuestionCountDown}
        setShowQuestionCountDown={setShowQuestionCountDown}
      />
    </Modal>
  );
}
