import React, { useMemo } from 'react';
import DefaultBanner from 'app/images/canaleducacao/Boas_vindas_CanalEducacao.png';
import EgepiBanner from 'app/images/egepi/EgepiBanner.png';

export default function WarningWall(props) {
  const { warning } = props;
  const appName = process.env.REACT_APP_NAME;

  const imageSource = useMemo(() => {
    if (warning) {
      if (warning?.image) {
        return warning?.image.url;
      }
    } else {
      if (appName === 'egepi') {
        return EgepiBanner;
      } else {
        return DefaultBanner;
      }
    }
  }, [warning]);

  return (
    <div className="warning-wall">
      <img
        className="warning-wall__img"
        src={imageSource}
        alt="imagem do aviso"
      />

      {warning?.link && warning?.image && (
        <a
          className="btn btn--wide btn--outline"
          href={warning?.link}
        >
          Acessar
        </a>
      )}

      {!warning?.image && warning?.title && warning?.description && (
        <div className="warning-wall__content">
          <div className="warning-wall__header">
            <h3
              className="warning-wall__title"
              title={warning?.title}
            >
              {warning?.title}
            </h3>
          </div>

          <div className="warning-wall__body">
            <p
              className="warning-wall__description"
              title={warning?.description}
            >
              {warning?.description}
            </p>
          </div>

          {warning?.link && (
            <div className="warning-wall__footer">
              <a
                className="btn btn--wide btn--outline"
                href={warning?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Acessar
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
