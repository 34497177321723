import React, { useState, useEffect } from 'react';
import spin from './img/spin-icon.svg';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function SpinWheel() {
  const [rotate, setRotate] = useState(0);

  const { currentChallenge, currentRound, getTaxonomies, taxonomies, currentTaxonomy } = useWeeklyChallenge();
  useEffect(() => {
    if (currentChallenge) getTaxonomies();
  }, []);

  useEffect(() => {
    if (taxonomies.length > 0 && currentTaxonomy) {
      const currentIndex = taxonomies.findIndex(taxonomy => taxonomy.id === currentTaxonomy.id);
      if (currentIndex !== -1) {
        const rotateValue = 360 / taxonomies?.length;
        setTimeout(() => setRotate(currentIndex * rotateValue + 1440), 500);
      }
    }
  }, [taxonomies]);

  return (
    <div className="spin-wheel-wrapper">
      <span className="spin-wheel-wrapper__count">Rodada {currentRound}/8</span>
      <div className="spin-wheel">
        <div
          className={`spin-wheel__roulette spin-wheel__roulette--${taxonomies?.length}-slices`}
          style={{ rotate: `-${rotate}deg` }}
        >
          {taxonomies?.map((slice, i) => {
            const rotateValue = 360 / taxonomies?.length;
            const rotate = rotateValue * i;

            return (
              <div
                className="spin-wheel__slice"
                style={{ backgroundColor: slice?.color, rotate: `${rotate}deg` }}
                key={i}
              >
                <span
                  className="spin-wheel__slice-text"
                  title={slice?.name}
                >
                  {slice?.name}
                </span>
              </div>
            );
          })}
        </div>

        <img
          className="spin-wheel__spin"
          src={spin}
          alt="ícone de girar"
        />
      </div>
    </div>
  );
}
