import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronLeft, FiEdit2, FiPenTool, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { navigate, useParams } from '@reach/router';
import { useFormik } from 'formik';
import 'tippy.js/dist/tippy.css';
import TextareaAutosize from 'react-autosize-textarea';
import userAvatar from 'app/images/user-avatar.svg';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Modal, { SimpleModal } from 'app/components/Modal';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { useSession } from 'app/hooks/useSession';
import Competence from './Competence';
import Timeline from '../Timeline';
import styles from './styles.module.scss';
import Avatar from 'app/components/Avatar';
import imgTia from 'app/components/TIA/img/tia-avatar.png';
import lampIcon from 'app/images/icons/lamp-icon.png';
import { statusBadgeText } from '../utils/BadgeEssay';
import TabNavBar from './TabNavBar';
import heic2any from 'heic2any';
import Loader from 'app/components/loader';
import { fn } from 'moment';

const MarkModalContentComponent = ({ content, fnDelete, fnUpdate }) => {
  return (
    <>
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ minWidth: '85%' }}>
          <span className="widget__title">{content?.title}</span>
          <p className="u-mt-3">{content?.body}</p>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FiTrash2
            color="red"
            onClick={fnDelete}
            style={{ cursor: 'pointer' }}
          />
          <FiEdit2
            color="blue"
            onClick={fnUpdate}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </>
  );
};

export default function EssayCorrection({ studentId, essayId, uri }) {
  const client = useClient();
  const essayRef = useRef();
  const { session } = useSession();
  const { t } = useTranslation();
  const [studentEssay, setStudentEssay] = useState({});
  const [initialIndex, setInitialIndex] = useState(null);
  const [finalIndex, setFinalIndex] = useState(null);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [observation, setObservation] = useState('');
  const [show, setShow] = useState(false);
  const [deletedCorrections, setDeletedCorrections] = useState([]);
  const [openMarkModal, setOpenMarkModal] = useState(false);
  const [markModalContent, setMarkModalContent] = useState(null);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [parsedText, setParsedText] = useState('');
  const [multipleSubmissions, setMultipleSubmissions] = useState('');
  const [isOverlapping, setIsOverlapping] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [activeSubmission, setActiveSubmission] = useState(Number(studentId));
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('redacao');
  const isAnnulled = studentEssay?.status === 'annulled';

  const { data: essay } = useQuery(`/essays/${essayId}/essay_students/${studentId}`);
  const { data: essayAnnulments } = useQuery(studentEssay?.status && !isAnnulled ? `essay_annulments` : null);

  const getEssayData = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setMultipleSubmissions(data?.['allow-multiple-submissions']);
      if (data?.['allow-multiple-submissions'] === true) {
        const { data } = await client.fetch(`/essays/${essayId}/users/${essay?.user?.id}/essay_students`);
        setSubmissions(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getEssayData();
    setStudentEssay(essay);
    if (essay && ['unreviewed'].includes(essay?.status)) {
      client.mutate(['essays', essayId, 'essay_students', studentId], { status: 'reviewing' });
    } else if (essay && ['reviewing'].includes(essay?.status) && essay?.corrector?.id && essay?.corrector?.id !== session?.user?.id) {
      Swal.fire({
        title: 'Ops!',
        text: 'Outro professor está corrigindo essa redação',
        confirmButtonText: 'OK'
      }).then(res => {
        if (res.isConfirmed) {
          navigate(`/redacao/acompanhamento/${essayId}`);
        }
      });
    }
  }, [essay]);

  const switchDigital = useFormik({
    initialValues: {
      digital: true,
      competence: 'c1'
    }
  });

  useEffect(() => {
    if (studentEssay && studentEssay?.status === 'annulled') {
      switchDigital.setFieldValue('digital', false);
    }
  }, [studentEssay]);

  const essayForm = useFormik({
    initialValues: {
      suggestion_to_improve: '',
      body: '',
      essay_student_competencies_attributes: [],
      essay_student_corrections_attributes: []
    }
  });

  const competencies = [
    { name: 'Competencia 1', description: 'Domínio da Norma Culta da Língua Portuguesa' },
    { name: 'Competencia 2', description: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto' },
    { name: 'Competencia 3', description: 'Seleção e Organização Lógica de Argumentos' },
    { name: 'Competencia 4', description: 'Construção Coesa e Coerente da Argumentação' },
    { name: 'Competencia 5', description: 'Proposição de Intervenção Respeitosa aos Direitos Humanos' }
  ];

  useEffect(() => {
    if (studentEssay) {
      essayForm.setValues({
        body: studentEssay.body,
        suggestion_to_improve: studentEssay.suggestion_to_improve,
        essay_student_corrections_attributes: studentEssay?.corrections,
        essay_student_competencies_attributes: studentEssay?.competencies
      });
    }

    if (studentEssay && studentEssay.essay_image && studentEssay.essay_image.url) {
      if (studentEssay.essay_image.url.includes('.heic') || studentEssay.essay_image.url.includes('.heif')) {
        convertHeicOrHeifToJpg(studentEssay.essay_image.url).then(url => {
          setStudentEssay(prevEssay => ({
            ...prevEssay,
            essay_image: { url }
          }));
        });
      }
    }
  }, [studentEssay]);

  const handleSubmitCorrection = () => {
    if (initialIndex !== null && finalIndex !== null) {
      const selection = { start_index: initialIndex, end_index: finalIndex, body: observation, created_at: new Date() };

      const corrections = resolveOverlappingSelections(selection);
      essayForm.setFieldValue(`essay_student_corrections_attributes`, [...corrections, selection]);
    }
  };

  const sendEvaluation = async () => {
    if (isAnnulled) {
      const { data: essayUpdated, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], {
        essay_student_content_attributes: {
          suggestion_to_improve: essayForm.values.suggestion_to_improve
        }
      });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        setStudentEssay(prev => ({ ...prev, suggestion_to_improve: essayUpdated.suggestion_to_improve }));
      }
    } else {
      const auxValues = { ...essayForm.values };
      auxValues.essay_student_corrections_attributes = auxValues.essay_student_corrections_attributes.map(correction => {
        const { title, ...rest } = correction; // Desestrutura para remover `title`
        return rest;
      });
      auxValues.essay_student_competencies_attributes = essayForm.values.essay_student_competencies_attributes.map(item => ({
        competency_id: String(item.competency_id)
      }));

      //Remove id
      auxValues.essay_student_corrections_attributes = auxValues.essay_student_corrections_attributes.map(item => {
        const { id, ...itemWithoutId } = item;
        return itemWithoutId;
      });

      const { error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_student_content_attributes: { ...auxValues } });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        navigate(`/redacao/acompanhamento/${essayId}`);
      }
    }
  };

  const convertHeicOrHeifToJpg = async imageUrl => {
    if (!imageUrl) {
      return null;
    }
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;

        setStudentEssay(prevEssay => ({
          ...prevEssay,
          essay_image: { url: base64data }
        }));
      };
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error('Erro ao converter imagem HEIC:', error);
    }
  };

  useEffect(() => {
    renderTextWithMarks();
  }, [essayForm.values.essay_student_corrections_attributes]);

  const renderTextWithMarks = () => {
    if (!essayForm.values.body) return;

    const tempElement = document.createElement('div');
    tempElement.innerHTML = essayForm.values.body.trim();
    const text = getPlainText(tempElement);

    tempElement.remove();

    const selections = essayForm?.values.essay_student_corrections_attributes?.sort((a, b) => a.start_index - b.start_index).filter(selection => selection.start_index !== -1) || [];
    if (!selections || selections.length === 0) {
      return text;
    }

    const elements = [];
    let acumulator = '';
    let selectionIndex = 0;
    let counter = 0;

    text?.split('').forEach((c, i) => {
      if (i >= selections[selectionIndex]?.start_index + counter && i < selections[selectionIndex]?.end_index + counter) {
        acumulator += c;
      } else if (i === selections[selectionIndex]?.end_index + counter) {
        const currentIndex = selectionIndex;
        const currentSelection = selections[currentIndex];
        currentSelection.title = 'Marcação';
        elements.push(
          <mark
            key={i}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenMarkModal(true);
              setMarkModalContent({
                content: currentSelection,
                fnDelete: () => handleDeleteCorrection({ selection: currentSelection }),
                fnUpdate: () => {
                  handleOpenUpdateCorrectionModal({ selection: currentSelection });
                  setOpenMarkModal(false);
                }
              });
            }}
          >
            {acumulator}
          </mark>
        );
        acumulator = '';
        elements.push(c);

        if (selectionIndex < selections.length - 1) {
          selectionIndex += 1;
        }
      } else {
        elements.push(c);
      }
    });

    if (acumulator) {
      const selection = selections[selectionIndex];
      selection.title = 'Marcação';

      elements.push(
        <mark
          key={text.length}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpenMarkModal(true);
            setMarkModalContent({
              content: selection,
              fnDelete: () => handleDeleteCorrection({ selection }),
              fnUpdate: () => {
                handleOpenUpdateCorrectionModal({ selection });
                setOpenMarkModal(false);
              }
            });
          }}
        >
          {acumulator}
        </mark>
      );
    }

    return <>{elements}</>;
  };

  const getPlainText = element => {
    return element.textContent;
  };

  const handleMouseUp = e => {
    const selection = window.getSelection();
    const selectedText = selection.toString();

    if (selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const spanNode = essayRef.current;

    if (spanNode.contains(range.commonAncestorContainer)) {
      const plainText = getPlainText(spanNode);
      const rangeStartNode = range.startContainer;

      let tempRange = document.createRange();
      tempRange.selectNodeContents(spanNode);
      tempRange.setEnd(range.startContainer, range.startOffset);

      const textBeforeSelection = getPlainText(tempRange.cloneContents());

      const startOffset = textBeforeSelection.length;
      const endOffset = startOffset + selectedText.length;

      if (selectedText.length === 0) return;

      const isOverlapping = essayForm?.values?.essay_student_corrections_attributes?.some(correction => {
        return (
          (startOffset >= correction.start_index && startOffset <= correction.end_index) || (endOffset >= correction.start_index && endOffset <= correction.end_index) || (startOffset <= correction.start_index && endOffset >= correction.end_index)
        );
      });

      if (isOverlapping) {
        setIsOverlapping(true);
      }
      setShowModal(true);
      setFinalIndex(endOffset);
      setInitialIndex(startOffset);
    }
  };

  const getRealSelectionIndex = (text, offset) => {
    return Math.min(text.length, offset);
  };
  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const existingCorrection = essayForm?.values?.essay_student_corrections_attributes?.find(correction => correction.id === id || (correction.start_index === initialIndex && correction.end_index === finalIndex));

      if (existingCorrection) {
        await handleUpdateCorrection({
          selection: {
            ...existingCorrection,
            body: observation,
            start_index: initialIndex,
            end_index: finalIndex
          }
        });
        setShowModal(false);
      } else {
        handleSubmitCorrection();
      }

      setInitialIndex(null);
      setFinalIndex(null);
      setObservation('');
    }
  };

  const handleDeleteCorrection = ({ selection }) => {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a marcação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.delete'),
      cancelButtonText: t('button.cancel'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        const filteredCorrections = essayForm?.values?.essay_student_corrections_attributes?.map(item => {
          if (item.id === selection.id) {
            return { ...item, start_index: -1, end_index: -1 };
          } else if (item.start_index === selection.start_index && item.end_index === selection.end_index) {
            return { ...item, start_index: -1, end_index: -1 };
          } else {
            return item;
          }
        });

        essayForm.setFieldValue(`essay_student_corrections_attributes`, filteredCorrections);
        setMarkModalContent(null);
        setOpenMarkModal(false);
      }
    });
  };

  const handleOpenUpdateCorrectionModal = ({ selection }) => {
    setShowModal(true);
    setId(selection.id);
    setInitialIndex(selection.start_index);
    setFinalIndex(selection.end_index);
    setObservation(selection.body);
  };

  const handleUpdateCorrection = async ({ selection }) => {
    const updatedCorrections = essayForm?.values?.essay_student_corrections_attributes?.map(item => {
      if (item.id === selection.id) {
        // Atualiza os valores da marcação selecionada
        return {
          ...item,
          start_index: initialIndex,
          end_index: finalIndex,
          body: observation
        };
      } else {
        return item;
      }
    });

    essayForm.setFieldValue(`essay_student_corrections_attributes`, updatedCorrections);
    setMarkModalContent(null);
    setOpenMarkModal(false);
    setObservation('');
    setInitialIndex(null);
    setFinalIndex(null);
  };

  const handleOpenCancelEssayModal = () => {
    setIsOpenCancel(prev => !prev);
  };

  const handleSubmitCancel = async e => {
    e.preventDefault();

    const data = new FormData(e.target);
    const formProps = Object.fromEntries(data);

    if (!formProps.reason) {
      toast.error('Selecione um motivo');
      return;
    }

    const { data: annulmentData, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_annulment_id: formProps.reason });

    if (error) {
      toast.error('Erro ao anular redação');
    } else {
      setStudentEssay(annulmentData);
      toast.success('Redação anulada com sucesso!');
      setIsOpenCancel(false);
    }
  };

  const resolveOverlappingSelections = selection => {
    const corrections = essayForm.values.essay_student_corrections_attributes;

    for (let i = 0; i < corrections.length; i++) {
      const correction = corrections[i];

      const isWithin = selection.start_index >= correction.start_index && selection.end_index <= correction.end_index;
      const startsWithin = selection.start_index < correction.end_index && selection.end_index >= correction.start_index;
      const engulfs = selection.start_index <= correction.start_index && selection.end_index >= correction.end_index;

      if (isWithin || startsWithin || engulfs) {
        if (correction.id) {
          correction.start_index = -1;
          correction.end_index = -1;
        } else {
          corrections.splice(i, 1);
          i--;
        }
      }
    }

    return corrections;
  };
  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/redacao',
        name: 'Redação',
        isActive: false
      },
      {
        route: `/redacao/acompanhamento/${essayId}`,
        name: essay?.topic,
        isActive: false
      },
      {
        route: uri,
        name: studentEssay?.user?.name,
        isActive: true
      }
    ]
  };

  const checkScore = score => {
    if (score <= 333) {
      return 'danger';
    } else if (score > 333 && score <= 666) {
      return 'warning';
    } else if (score > 666) {
      return 'success';
    }
  };

  useEffect(() => {
    getEssayData();
  }, [studentId]);

  const totalPoints = useMemo(() => {
    return essayForm?.values?.essay_student_competencies_attributes?.reduce((acc, item) => {
      return acc + Number(item.score);
    }, 0);
  }, [essayForm.values.essay_student_competencies_attributes]);

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />
      <TabNavBar
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        essayStatus={studentEssay?.status}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={styles['page-content']}>
          {selectedTab === 'redacao' && (
            <>
              <div className={styles.aside}>
                {multipleSubmissions ? (
                  <div className="essay-widget">
                    <div
                      className="round-dg__user u-cursor-pointer u-mb-3"
                      onClick={() => navigate(`/usuario/metricas/${studentEssay?.user?.id}`)}
                    >
                      <img
                        className="avatar avatar--xs"
                        src={studentEssay?.user?.image || userAvatar}
                        alt="Imagem do usuário"
                      />

                      <div className="round-dg__user-inner">
                        <h3
                          className="round-dg__title"
                          title={studentEssay?.user?.name}
                        >
                          {studentEssay?.user?.name}
                        </h3>
                      </div>
                    </div>
                    <Timeline
                      submissions={submissions}
                      activeSubmission={activeSubmission}
                      setActiveSubmission={setActiveSubmission}
                      essayId={essayId}
                    />
                    {studentEssay?.['grammar_quality'] && (
                      <div className={`${styles['aside-footer']} ${styles['aside-footer-border']} u-mt-4`}>
                        <h2>Avaliação ortográfica</h2>
                        <span className={`square-tag square-tag--sm square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                          {studentEssay?.['grammar_quality']}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="essay-widget">
                    <div className={styles['score-card']}>
                      <p className={`${styles['score-card-value']} ${styles[`score-card-value--${checkScore(totalPoints)}`]} u-mb-0`}>{totalPoints}</p>
                      <span>pontos</span>
                    </div>
                    <div className={styles['aside-footer']}>
                      <span className="badge badge--tiny">{statusBadgeText[essay?.status]}</span>
                      <p className="u-mb-0">em 01/01/2024</p>
                    </div>
                    {studentEssay?.['grammar_quality'] && (
                      <div className={`${styles['aside-footer']} ${styles['aside-footer-border']} u-mt-4`}>
                        <h2>Avaliação ortográfica</h2>
                        <span className={`square-tag square-tag--sm square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                          {studentEssay?.['grammar_quality']}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <button
                  className={`btn--primary btn--small ${styles['annul-btn']} ${styles['annul-btn--only-xxl']} u-mt-4`}
                  onClick={handleOpenCancelEssayModal}
                >
                  Anular redação
                </button>
              </div>
              <div className={styles['page-content-main']}>
                <div className={` ${styles['tia-container']} u-mb-4`}>
                  <Avatar
                    className={styles['tia-container-avatar']}
                    src={imgTia}
                    alt="T.I.A"
                    data-tooltip-id="tia"
                    data-tooltip-place="top-start"
                    data-tooltip-variant="light"
                  />
                  <div className={styles['tia-container-text-balloon']}>
                    <div className="widget__header">
                      <div className={styles['tia-container-title']}>
                        <h2>Sugestões da T.I.A para melhorar sua redação</h2>
                        <img
                          src={lampIcon}
                          className={styles['tia-container-title-icon']}
                        />
                      </div>
                      <textarea
                        type="text"
                        onChange={e => essayForm.setFieldValue('suggestion_to_improve', e.target.value)}
                        className={styles.textarea}
                        value={essayForm?.values['suggestion_to_improve']}
                      />
                    </div>
                  </div>
                </div>

                <div className="skill-evaluation">
                  <div className="skill-evaluation__header"></div>

                  <div className="skill-evaluation__essay">
                    {studentEssay?.status !== 'annulled' && (
                      <span
                        className="badge badge--redaction"
                        style={{ height: 'auto', whiteSpace: 'normal' }}
                      >
                        Para adicionar observações ao texto, basta selecionar o trecho desejado
                      </span>
                    )}

                    <div className="skill-evaluation__essay-header">
                      <h2 className="skill-evaluation__essay-title">Redação avaliada</h2>
                      {studentEssay?.status !== 'annulled' && (
                        <div className="form__check form__switch u-mb-0">
                          <input
                            className="form__check-input"
                            id="digital"
                            name="digital"
                            type="checkbox"
                            checked={switchDigital.values.digital}
                            onChange={() => {
                              switchDigital.setFieldValue('digital', !switchDigital.values.digital);
                            }}
                          />
                          <label htmlFor="digital">{switchDigital.values.digital ? 'Digital' : 'Cursiva'}</label>
                        </div>
                      )}
                    </div>

                    {switchDigital.values.digital ? (
                      <div className="skill-evaluation__essay-body">
                        <div
                          className="skill-evaluation__essay-text"
                          style={{ overflow: 'visible' }}
                        >
                          {essayForm.values.body && (
                            <span
                              id="essay-body-raw-text"
                              ref={essayRef}
                              className="skill-evaluation__essay-raw_text"
                              style={{ whiteSpace: 'pre-wrap' }}
                              onMouseUp={handleMouseUp}
                            >
                              {renderTextWithMarks()}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <img
                          className="u-cursor-pointer"
                          src={studentEssay?.essay_image?.url}
                          alt="prova digitalizada"
                          onClick={() => setShow(true)}
                        />
                        <SimpleModal
                          show={show}
                          onClose={() => setShow(false)}
                          contentClassName="simple-modal__content--lg"
                        >
                          <img
                            src={studentEssay?.essay_image?.url}
                            alt="prova digitalizada"
                          />
                        </SimpleModal>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${styles.aside} ${styles['aside-competences']}`}>
                <button
                  onClick={sendEvaluation}
                  className="btn btn--wide btn--full btn--primary u-mb-3"
                >
                  Salvar correção
                </button>
                <div className="essay-widget">
                  <button
                    className={`btn btn--outline btn--small ${styles['annul-btn']}`}
                    onClick={handleOpenCancelEssayModal}
                  >
                    Anular redação
                  </button>
                  <h2 className="widget__title">Avaliação de competências</h2>
                  {essayForm?.values?.essay_student_competencies_attributes?.map((item, index) => (
                    <Competence
                      competence={item}
                      item={competencies[index]}
                      form={essayForm}
                      studentEssay={studentEssay}
                      switchDigital={switchDigital}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          {selectedTab === 'competencias' && (
            <>
              <button
                onClick={sendEvaluation}
                className="btn btn--wide btn--primary"
              >
                Enviar para o estudante
              </button>
              <div className={styles['competences-tab']}>
                <div className={`${styles.competences}`}>
                  <h2 className="widget__title  u-mb-3">Avaliação de competências</h2>
                  <p>Pontuação total: {totalPoints} pontos</p>
                  <div className={styles['competences-list']}>
                    {essayForm?.values?.essay_student_competencies_attributes?.map((item, index) => (
                      <Competence
                        competence={item}
                        item={competencies[index]}
                        form={essayForm}
                        studentEssay={studentEssay}
                        switchDigital={switchDigital}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Modal
        show={isOpenCancel}
        onClose={() => setIsOpenCancel(false)}
      >
        <div style={{ width: '100%', borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
          <h2 className="form__title">Anular redação</h2>
          <span>Selecione o motivo da anulação:</span>
        </div>

        <form onSubmit={handleSubmitCancel}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
            {essayAnnulments?.map(item => (
              <label key={item.id}>
                <input
                  id="reason"
                  type="radio"
                  value={item.id}
                  name="reason"
                />
                {item.body}
              </label>
            ))}
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--full"
          >
            Salvar
          </button>
        </form>
      </Modal>

      <SimpleModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setIsOverlapping(false);
          setInitialIndex(null);
          setFinalIndex(null);
          setObservation('');
        }}
        hideCloseButton
      >
        <label className="form__label">Observação</label>
        <textarea
          value={observation}
          onChange={e => setObservation(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {'Aperte ENTER para salvar o comentário'}
        <br />
        {isOverlapping && <span style={{ fontSize: 16, color: '#900' }}>Você está sobrescrevendo marcações já efetuadas</span>}
      </SimpleModal>

      <SimpleModal
        show={openMarkModal}
        onClose={() => setOpenMarkModal(false)}
        contentClassName="simple-modal__content--md"
      >
        {markModalContent && (
          <MarkModalContentComponent
            content={markModalContent.content}
            fnDelete={markModalContent.fnDelete}
            fnUpdate={markModalContent.fnUpdate}
          />
        )}
      </SimpleModal>
    </main>
  );
}
