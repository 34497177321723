import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useQuery } from 'jsonapi-react';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ViewGRE from './ViewGRE';
import Loader from 'app/components/loader';
import { TableGRE } from './TableGRE';
import { ReportsChart } from 'app/components/ReportsChart';
import useReportAbilities from 'app/hooks/useReportAbilities';
import ReportForm from 'app/components/ReportForm';

export default function RegistrationReport() {
  const [items, setItems] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      'start-date': null,
      'end-date': moment().format('DD/MM/YYYY')
    }
  });

  const { data: courses = [], isLoading: isLoadingCourses } = useQuery('/courses/simple?filter[is_public]=false');

  const [showModule, setShowModule] = useState(false);
  const [selectedRegional, setSelectedRegional] = useState(null);
  const today = moment().format(t('datesForm.formatDate'));
  const { reportAbilities, getReportAbilities } = useReportAbilities();

  let filter = {};

  if (filters.values.course && filters.values.course !== 'blank') {
    filter.course_id = filters.values.course;
  }

  if (filters.values['start-date'] && filters.values['end-date']) {
    filter.date_between = {
      initial: filters.values['start-date'],
      final: filters.values['end-date']
    };
  }

  if (filters.values['start-date'] === 'Invalid date' || null) {
    filter.date_between = {};
  }

  const { data: report, isLoadingReport } = useQuery([
    'regionals/registration_report',
    {
      filter
    }
  ]);

  const startDate = filters.values['start-date'] ? moment(filters.values['start-date'], 'DD/MM/YYYY').format('DD/MM/YYYY') : null;
  const endDate = filters.values['end-date'] ? moment(filters.values['end-date'], 'DD/MM/YYYY').format('DD/MM/YYYY') : null;

  const kpis = [
    {
      id: 1,
      title: startDate !== 'Invalid date' && startDate !== null && endDate !== 'Invalid date' && endDate !== null ? `Estudantes cadastrados de ${startDate} a ${endDate}` : 'Estudantes cadastrados geral',
      value: report?.registeredStudents,
      help: 'Número total de estudantes cadastrados de acordo com o filtro selecionado.',
      variation: 'report-kpi-card--check'
    },
    {
      id: 2,
      title: startDate !== 'Invalid date' && startDate !== null && endDate !== 'Invalid date' && endDate !== null ? `Maior número de cadastros de ${startDate} a ${endDate}` : 'Maior número de cadastros',
      value: report?.mostRegistrations?.name,
      help: 'Analise as estratégias da região com maior número de cadastros para identificar práticas bem-sucedidas.',
      variation: 'report-kpi-card--users'
    },
    {
      id: 3,
      title: startDate !== 'Invalid date' && startDate !== null && endDate !== 'Invalid date' && endDate !== null ? `Menor número de cadastros de ${startDate} a ${endDate}` : 'Menor número de cadastros',
      value: report?.leastRegistrations?.name,
      help: 'Analise as estratégias da região com menor número de cadastros para desenvolver ações de melhoria.',
      variation: 'report-kpi-card--user'
    }
  ];

  useEffect(() => {
    getReportAbilities();
  }, []);

  useEffect(() => {
    setSelectedRegional(null);
    setShowModule(false);
    if (report?.regionals) {
      const parsedReports = report.regionals.map((item, index) => ({ ...item, position: index + 1 }));
      setItems(parsedReports);
    }
  }, [report]);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Cadastros
        </h1>
        <p className="report__description">Visão global dos cadastros de usuários</p>
      </header>

      <div className="report__body">
        <div className="report__filter">
          <ReportForm.Select
            label={t('exams.selectCourse')}
            value={filters.values.course}
            blankLabel={t('metrics.blankLabelSelect')}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
          <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              min={today}
              value={moment(filters.values['start-date'], 'DD/MM/YYYY').format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('start-date', moment(e.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY'))}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="end-date"
              name="end-date"
              type="date"
              min={today}
              value={moment(filters.values['end-date'], 'DD/MM/YYYY').format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('end-date', moment(e.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY'))}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div>
        </div>

        {isLoadingReport && <Loader />}

        {!isLoadingReport && (
          <>
            <div className="report-widget">
              <div className="report-widget__header">
                <h3 className="report-widget__title">Visão geral</h3>
              </div>

              <div className="report-widget__body">
                <div className="report-kpi-card-wrapper">
                  {kpis.map(kpi => (
                    <div
                      className={`report-kpi-card ${kpi.variation}`}
                      key={kpi.id}
                    >
                      {kpi.help && (
                        <>
                          <FiHelpCircle
                            className="report-kpi-card__help-icon"
                            data-tooltip-id={kpi.id}
                            data-tooltip-content={kpi.help}
                          />
                          <ReactTooltip
                            id={kpi.id}
                            className="tooltip"
                          />
                        </>
                      )}
                      <h3 className="report-kpi-card__title">{kpi.title}</h3>
                      <p
                        className="report-kpi-card__value"
                        title={kpi.value}
                      >
                        {kpi.value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['mapa-de-usuarios-cadastrados'] && (
              <div className="report-widget">
                <div className="report-widget__header">
                  <h3 className="report-widget__title">
                    {startDate !== 'Invalid date' && startDate !== null && endDate !== 'Invalid date' && endDate !== null ? `Mapa de usuários cadastrados de ${startDate} a ${endDate}` : 'Mapa geral de usuários cadastrados'}
                  </h3>

                  <FiHelpCircle
                    className="report-widget__help-icon"
                    data-tooltip-id="access"
                    data-tooltip-content="O gráfico de bolhas exibe o número de usuários cadastrados por região. O tamanho de cada bolha corresponde à quantidade de usuários, facilitando a comparação visual entre as regiões."
                  />
                  <ReactTooltip
                    id="access"
                    className="tooltip"
                  />
                </div>

                <div className="report-widget__body">
                  <ReportsChart
                    report={items}
                    selectedRegional={selectedRegional}
                    setSelectedRegional={setSelectedRegional}
                    setShowModule={setShowModule}
                  />
                </div>
              </div>
            )}

            {reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['ranking'] && (
              <div className="report-widget">
                <div className="report-widget__header">
                  <h3 className="report-widget__title">
                    {startDate !== 'Invalid date' && startDate !== null && endDate !== 'Invalid date' && endDate !== null ? `Ranking de usuários cadastrados de ${startDate} a ${endDate}` : 'Ranking geral de usuários cadastrados'}
                  </h3>

                  <div className={!showModule ? 'filter-bar u-mb-0 filter-bar--borderless' : 'filter-bar u-mb-0'}>
                    <div className="filter-bar__inner">
                      {!showModule && (
                        <input
                          className="report-form__control"
                          aria-label={t('textsCommon.research')}
                          placeholder={t('textsCommon.research')}
                          type="search"
                          name="search"
                          id="search"
                          onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                        />
                      )}

                      {showModule && (
                        <>
                          <button
                            className="filter-bar__back filter-bar__back--disabled"
                            style={{ alignSelf: 'center' }}
                            onClick={() => setShowModule(false)}
                          >
                            <span className="filter-bar__back-container">
                              <span className="filter-bar__title">GREs</span>
                            </span>
                          </button>
                          <button
                            className="filter-bar__back"
                            onClick={() => {
                              setItems(report?.regionals);
                              setShowModule(false);
                              setSelectedRegional(null);
                            }}
                          >
                            <span className="card__icon-wrapper card__icon-wrapper--sm">
                              <FiChevronLeft className="card__icon" />
                            </span>
                            <span className="filter-bar__back-container">
                              <span className="filter-bar__title">{selectedRegional?.name}</span>
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="report-widget__body">
                  {!showModule ? (
                    <div className="round-dg-wrapper">
                      <TableGRE
                        selectedRegional={selectedRegional}
                        regionals={items}
                        searchValue={filters.values.searchTerm}
                        onOpen={item => {
                          setSelectedRegional(item);
                          setShowModule(true);
                        }}
                      />
                    </div>
                  ) : (
                    <ViewGRE
                      items={items}
                      gre={selectedRegional}
                      setItems={setItems}
                      filter={filter}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
}
