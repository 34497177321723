import { FiX } from 'react-icons/fi';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import Avatar from '../Avatar';
import { useClient } from 'jsonapi-react';

export default function DuelNotification({ duelData, setDuelData }) {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(100);
  const client = useClient();

  const handleAcceptDuel = async () => {
    const { error } = await client.mutate(`duels/${duelData?.duel_id}/accept`, {}, { method: 'PUT' });

    if (error) {
      toast.error('Erro ao aceitar duelo');
    }

    setVisible(false);
    setDuelData(null);
  };

  const handleRefuseDuel = async () => {
    const { error } = await client.mutate(`duels/${duelData?.duel_id}/refuse`, {}, { method: 'PUT' });

    if (error) {
      toast.error('Erro ao recusar duelo');
    }

    setVisible(false);
    setDuelData(null);
  };

  useEffect(() => {
    if (visible === true) {
      setProgress(100);
      const interval = setInterval(() => {
        setProgress(prev => (prev > 0 ? prev - 1.5 : 0));
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [visible]);

  useEffect(() => {
    if (progress === 0) {
      setVisible(false);
      setDuelData(null);
    }
  }, [progress, setDuelData]);

  useEffect(() => {
    if (duelData) {
      setVisible(true);
    }
  }, [duelData]);

  const mockImg = 'https://r2.erweima.ai/imgcompressed/img/compressed_95f6dc695351dbb5cf511ee473897718.webp';

  return (
    <div className={`${styles['duel-notification']} ${visible && styles['duel-notification--visible']} `}>
      <FiX onClick={() => setVisible(false)} />
      <div className={styles['duel-notification__content']}>
        <Avatar
          src={duelData?.creator?.image?.url}
          className={`avatar--md`}
        />
        <div>
          <span className={styles['duel-notification__content-name']}>{duelData?.creator?.name}</span>
          <p className="u-mb-0">te desafiou para um duelo</p>
          <p className="u-mb-0">Responda antes que o convite expire</p>
          <div className={styles['duel-notification__content-buttons']}>
            <button
              className="btn btn--outline"
              onClick={handleAcceptDuel}
            >
              Aceitar
            </button>
            <span onClick={handleRefuseDuel}>Recusar</span>
          </div>
        </div>
      </div>
      <div
        className={styles['duel-notification__bar']}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}
