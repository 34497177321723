import { useState } from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';

export function TableGRE({ regionals = [], onOpen = null, searchValue }) {
  const [sortDirection, setSortDirection] = useState('desc');

  const sorted = regionals.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a.registrationsCount - b.registrationsCount;
    } else {
      return b.registrationsCount - a.registrationsCount;
    }
  });

  return (
    <table
      className="round-dg round-dg--light"
      style={{ minWidth: '720px' }}
    >
      <thead className="round-dg__header">
        <tr className="round-dg__row">
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            style={{ width: '80px' }}
          >
            Posição
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            style={{ width: '44px' }}
          />
          <th className="round-dg__cell-header round-dg__cell-header--sm">Escola</th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
            style={{ width: '116px' }}
          >
            Cadastrados{' '}
            <span
              onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
            >
              <FiChevronDown
                style={{ transform: sortDirection === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)' }}
                className="card__icon"
              />
            </span>
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
            style={{ width: '176px' }}
          >
            % do total de cadastros
          </th>
        </tr>
      </thead>

      <tbody className="round-dg__body">
        {regionals.length > 0 &&
          sorted
            .filter(item => !searchValue || item.name.toLowerCase().includes(searchValue))
            .map((item, i) => (
              <tr
                key={item.id}
                className={`round-dg__row ${onOpen && item.id !== 0 ? 'u-cursor-pointer' : 'u-cursor-default'}`}
                onClick={onOpen && item.id !== 0 ? () => onOpen(item) : null}
              >
                <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{item.position}</th>
                <td className="round-dg__cell round-dg__cell--sm">
                  {item.position === 1 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                  {item.position === 2 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                  {item.position === 3 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div className="round-dg__user">
                    <div className="round-dg__user-inner">
                      <h3 className="round-dg__title">{item.name}</h3>
                    </div>
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm u-text-right">{item.registrationsCount}</td>
                <td className="round-dg__cell round-dg__cell--sm u-text-right">{item.percent ? item.percent.toFixed(2) : 0}%</td>
              </tr>
            ))}
      </tbody>
    </table>
  );
}
