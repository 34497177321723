import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import Modal from 'app/components/Modal';
import EventForm from 'app/components/eventForm';
import { useSession } from 'app/hooks/useSession';
import { TeacherDashboardProvider } from './providers/TeacherDashboardProvider';
import DashboardMetrics from './DashboardMetrics';
import Survey from 'app/components/Survey';
import { useSurvey } from 'app/hooks/useSurvey';
import DashboardStudent from './DashboardStudent';
import ViewEvent from './ViewEvent';
import Onboarding from 'app/components/Onboarding';
import toast from 'react-hot-toast';
import getPermission from 'app/utils/getPermission';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import MockQuestionBookCard from './MockQuestionBookCard';
import MockQuestionBook from './MockQuestionBook';
import Calendar from 'react-calendar';
import { navigate } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import { FiMoreHorizontal } from 'react-icons/fi';
import bannerEvento from 'app/images/placeholders/banner-evento.png';
import DashboardTeacher from './DashboardTeacher';

function Dashboard(props) {
  return (
    <TeacherDashboardProvider>
      <ScreenContentDashboard {...props} />
    </TeacherDashboardProvider>
  );
}

function ScreenContentDashboard(props) {
  const { t } = useTranslation();
  const client = useClient();
  const [slots, setSlots] = useState([]);
  const [editingEvent, setEditingEvent] = useState();
  const [events, setEvents] = useState([]);
  const [eventDates, setEventDates] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showFormEvent, setShowFormEvent] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { getStudentSurvey, currentStudentSurvey } = useSurvey();
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const path = props.path;
  const [showMockQuestionBook, setShowMockQuestionBook] = useState(session?.user?.userDetail?.showQuestionBookBanner);
  const [mockQuestionBook, setmockQuestionBook] = useState({});
  const [mockLoading, setMockLoading] = useState(true);
  const [hasClassrooms, setHasClassrooms] = useState(false);
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);
  const [visibleEvents, setVisibleEvents] = useState(2);

  const currentDate = moment();
  const eventsToday = (events || []).filter(i => i['initial-date'] === moment(selectedDate).format('YYYY-MM-DD'));
  const authorityLevel = getAuthorityLevel(session);
  const hasMoreEvents = visibleEvents < eventsToday.length;

  const fetchSlots = async () => {
    try {
      const today = moment().format('YYYY-MM-DD');
      const { data } = await client.fetch(`slots?filter[date_between][initial]=${today}&filter[date_between][final]=${today}`);
      setSlots(data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchEvents = async initDate => {
    try {
      const initialDate = moment(initDate).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(initDate).endOf('month').format('YYYY-MM-DD');

      const { data } = await client.fetch(`events?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${endDate}`);
      setEvents(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getClassrooms = async () => {
    setLoadingClassrooms(true);
    const { data, error } = await client.fetch(`classrooms?filter[users.id]=${session.user.id}`);
    if (error) {
      console.log(error);
    } else {
      if (data.length === 0) {
        setLoadingClassrooms(false);
      } else {
        setLoadingClassrooms(false);
        setHasClassrooms(true);
      }
    }
  };

  const confirmDeleteEvent = event => {
    Swal.fire({
      title: t('projects.warningDeleteEvent'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteEvent(event);
      }
    });
  };

  const deleteEvent = async event => {
    const { error } = await client.delete(['events', event.id]);
    if (!error) {
      toast.success(t('toast.successDeleteEvent'));
      setEvents(prevState => prevState.filter(item => item.id !== event.id));
    }
  };

  const editEvent = event => {
    setEditingEvent(event);
    setShowFormEvent(true);
  };

  const closeFormEvent = () => {
    setShowFormEvent(false);
    setEditingEvent(null);
  };

  const onCloseSurvey = () => {
    setShowSurvey(false);
    getStudentSurvey();
  };

  const onActiveStartDateChange = ({ action, activeStartDate, value, view }) => {
    setSelectedDate(activeStartDate);
    fetchEvents(activeStartDate);
  };

  const getmockQuestionBook = async () => {
    setMockLoading(true);
    try {
      const { data, error } = await client.fetch('question_books/next');
      if (error) {
        toast.error(t('toast.errorGetSimulations'));
      } else {
        setmockQuestionBook(data);
        setMockLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowMore = () => {
    setVisibleEvents(eventsToday.length);
  };

  useEffect(() => {
    if (!profiles.includes('Professor')) {
      fetchSlots();
    }
    fetchEvents(selectedDate);
    getClassrooms();
    getStudentSurvey();
    if (authorityLevel === 'student') {
      getmockQuestionBook();
    }
  }, []);

  useEffect(() => {
    setEventDates((events || []).map(i => moment(i['initial-date']).format('YYYY-MM-DD')));
  }, [events]);

  useEffect(() => {
    const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
    const newValue = mockQuestionBook && !mockQuestionBook['is-question-book-done'] && session?.user?.userDetail?.showQuestionBookBanner && !mockLoading && !profiles.includes('Administrador');
    setShowMockQuestionBook(newValue);
  }, [mockLoading, mockQuestionBook, session]);

  return (
    <>
      <main className="main-content">
        <div className="page">
          <div className="page__col">
            {profiles.includes('Administrador') ? (
              <DashboardMetrics />
            ) : profiles.includes('Aluno') ? (
              <DashboardStudent
                loading={loadingClassrooms}
                user={session.user}
                hasClassrooms={hasClassrooms}
              />
            ) : (
              <DashboardTeacher />
            )}
          </div>

          <aside
            className="page__aside page__aside--calendar"
            style={{ overflow: 'initial' }}
          >
            {showMockQuestionBook && profiles.includes('Aluno') && <MockQuestionBookCard mockQuestionBook={mockQuestionBook} />}

            <div className="react-calendar-wrapper">
              <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                minDetail="month"
                maxDetail="month"
                onActiveStartDateChange={onActiveStartDateChange}
                tileClassName={({ date }) =>
                  eventDates.includes(moment(date).format('YYYY-MM-DD')) ? (currentDate.isSameOrBefore(moment(date)) || currentDate.format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ? 'active-item bg-red' : 'active-item bg-green') : null
                }
                locale={t('language.language')}
              />
              {getPermission('Criar eventos', 'Eventos') && (
                <button
                  onClick={() => setShowFormEvent(true)}
                  className="btn btn--outline btn--full"
                >
                  {t('events.buttonAddEvent')}
                </button>
              )}

              {getPermission('Visualizar eventos', 'Eventos') && eventsToday.length > 0 && (
                <div className="sidebar-card-wrapper">
                  {events &&
                    events.length > 0 &&
                    eventsToday.length > 0 &&
                    eventsToday.slice(0, visibleEvents).map((event, index) => (
                      <div
                        key={index}
                        className="sidebar-card"
                        onClick={() => navigate(`/evento/${event.id}`)}
                      >
                        {getPermission('Editar eventos', 'Eventos') || getPermission('Excluir eventos', 'Eventos') ? (
                          <Dropdown>
                            <Dropdown.Toggle>
                              <FiMoreHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              {getPermission('Editar eventos', 'Eventos') && <Dropdown.Item onClick={() => editEvent(event)}>{t('button.edit')}</Dropdown.Item>}
                              {getPermission('Excluir eventos', 'Eventos') && <Dropdown.Item onClick={() => confirmDeleteEvent(event)}>{t('button.delete')}</Dropdown.Item>}
                            </Dropdown.Content>
                          </Dropdown>
                        ) : null}

                        <img
                          className="sidebar-card__img"
                          src={event.image?.url || bannerEvento}
                          alt="banner evento"
                        />
                        <div className="sidebar-card__body">
                          <h3 className="sidebar-card__title">
                            {event['initial-time']} - {event['end-time']}
                          </h3>

                          <p className="sidebar-card__description">{event.title}</p>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {eventsToday.length > 2 && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    type="button"
                    className="btn btn--link"
                    onClick={hasMoreEvents ? handleShowMore : () => setVisibleEvents(2)}
                  >
                    {hasMoreEvents ? 'Ver mais' : 'Ver menos'}
                  </button>
                </div>
              )}

              {eventsToday.length === 0 && (
                <EmptyState
                  type="date"
                  title={t('emptyState.eventTitle')}
                  text={t('emptyState.eventText')}
                  bgless
                />
              )}
            </div>
          </aside>
        </div>
      </main>

      <Modal
        show={showFormEvent}
        onClose={closeFormEvent}
      >
        <EventForm
          onClose={() => setShowFormEvent(false)}
          editingEvent={editingEvent}
          events={events}
          setEvents={setEvents}
        />
      </Modal>

      <Modal
        show={showSurvey}
        onClose={() => setShowSurvey(false)}
        contentClassName="modal__content--p-md"
      >
        {currentStudentSurvey && (
          <Survey
            studentSurvey={currentStudentSurvey}
            onClose={onCloseSurvey}
          />
        )}
      </Modal>

      {mockQuestionBook && showMockQuestionBook && !mockLoading && profiles.includes('Aluno') && (
        <MockQuestionBook
          setShowMockQuestionBook={setShowMockQuestionBook}
          showMockQuestionBook={showMockQuestionBook}
          mockQuestionBook={mockQuestionBook}
        />
      )}

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleDashboard')}
        videoId="oO3DxD_nqws"
      />
    </>
  );
}

export { Dashboard as default, ViewEvent };
