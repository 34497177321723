import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { FiX } from 'react-icons/fi';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import DuelIcon from 'app/components/Icons/DuelIcon';
import checkIcon from './img/check-icon.svg';
import xIcon from './img/x-icon.svg';
import crownIcon from './img/crown-icon.png';
import crownAndLeaf from './img/crown_and_leaf.png';
import star from './img/star1.png';
import EmptyState from 'app/components/EmptyState';
import { useSession } from 'app/hooks/useSession';
import Avatar from 'app/components/Avatar';
import styles from './styles.module.scss';

export default function Result({ setOpenPlayerModal, setNewDuelModal }) {
  const { currentRound, currentChallenge, setCurrentStep, setCurrentRound, currentStep } = useWeeklyChallenge();
  const [selectedTab, setSelectedTab] = useState(currentRound - 1);
  const [scores, setScores] = useState();
  const [winner, setWinner] = useState();
  const session = useSession();
  const userAvatar = session?.user?.image?.url;
  const opponentAvatar = currentChallenge?.opponent?.image?.url;
  const client = useClient();

  const tabs = ['Rodada 1', 'Rodada 2', 'Rodada 3', 'Rodada 4', 'Rodada 5', 'Rodada 6', 'Rodada 7', 'Rodada 8', 'Resultado'];

  const handleNextQuestion = () => {
    setCurrentStep(1);
    setCurrentRound(prev => prev + 1);
  };

  const getScore = async () => {
    const { data, error } = await client.fetch(`/duels/${currentChallenge?.id}/score`);

    if (error) {
      toast.error('Erro ao buscar resultados');
    } else {
      setScores(data);
    }
  };
  const getWinner = async () => {
    const { data, error } = await client.fetch(`/duels/${currentChallenge?.id}/winner`);

    if (error) {
      toast.error('Erro ao buscar vencedor');
    } else {
      setWinner(data);
    }
  };

  useEffect(() => {
    if (currentStep === 3) {
      getScore();
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedTab === 8 && !winner) {
      getWinner();
    }
  }, [selectedTab]);

  const currentScore = scores?.['scores_and_durations'][selectedTab];

  const creatorId = currentChallenge?.['creator-id']?.toString();
  const opponentId = currentChallenge?.['invited-id']?.toString();

  const creatorScore = currentScore?.answers[0]?.users?.[creatorId];
  const opponentScore = currentScore?.answers[0]?.users?.[opponentId];

  const formatDuration = durationInSeconds => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  console.log(currentRound);

  return (
    <section className={`${styles['player-modal__section']} ${styles['player-modal__section--result']}`}>
      <div className={styles['player-modal__hat']}>
        <span>{currentChallenge?.['parent-taxonomy'].name}</span>
        <p className={styles['player-modal__name-section-subtitle']}>
          <DuelIcon className={styles['player-modal__name-section-icon']} /> Duelo
        </p>
      </div>
      <div className={styles['player-modal__result-section']}>
        {/* VENCEDOR DEFINIDO */}
        {selectedTab === 8 && winner && (
          <div className={styles['player-modal__effect-background']}>
            <div className={styles['player-modal__participant']}>
              <div className={`${styles['player-modal__participant--img']}`}>
                <Avatar
                  src={winner?.['winner_id'] === session?.user?.id ? userAvatar : opponentAvatar}
                  style={{ width: '100px', height: '100px' }}
                />
                <>
                  <img
                    src={crownAndLeaf}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-leaf']}`}
                  />
                  <img
                    src={star}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-star-1']}`}
                  />
                  <img
                    src={star}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-star-2']}`}
                  />
                  <img
                    src={star}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-star-3']}`}
                  />
                  <img
                    src={star}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-star-4']}`}
                  />
                </>
              </div>
              <span className={styles['player-modal__participant-name']}>{winner?.['winner_id'] === session?.user?.id ? session?.user?.name : currentChallenge?.opponent?.name}</span>
              <span className={styles['player-modal__participant-score']}>{winner?.['winner_score'].toFixed(2)} pts</span>
            </div>
          </div>
        )}
        {/* EXIBIÇÃO DOS PLACARES: FULANO X CICLANO */}
        {selectedTab !== 8 && (
          <div className={styles['player-modal__infos']}>
            <div className={styles['player-modal__participant']}>
              <div className={`${styles['player-modal__participant--img']} u-mb-3`}>
                <Avatar
                  src={currentChallenge?.['creator-id'] === session?.user?.id ? userAvatar : opponentAvatar}
                  style={{ width: '70px', height: '70px' }}
                />
                {opponentScore && creatorScore && creatorScore.correct && !opponentScore.correct && (
                  <img
                    src={crownIcon}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-crown']}`}
                  />
                )}
                <span>{creatorScore ? parseInt(creatorScore?.score) : '0'} pts</span>
              </div>
              <span>{currentChallenge?.['creator-id'] === session?.user?.id ? session?.user?.name : currentChallenge?.opponent?.name}</span>
            </div>
            <div className={styles['player-modal__infos--values']}>
              <span>{creatorScore?.correct ? '1' : '0'}</span>
              <FiX />
              <span>{opponentScore?.correct ? '1' : '0'}</span>
            </div>
            <div className={styles['player-modal__participant']}>
              <div className={`${styles['player-modal__participant--img']} u-mb-3`}>
                <Avatar
                  src={currentChallenge?.['invited-id'] === session?.user?.id ? userAvatar : opponentAvatar}
                  style={{ width: '70px', height: '70px' }}
                />
                {opponentScore && creatorScore && opponentScore.correct && !creatorScore.correct && (
                  <img
                    src={crownIcon}
                    alt="coroa"
                    className={`${styles['player-modal__participant--img-crown']}`}
                  />
                )}
                <span>{opponentScore ? parseInt(opponentScore?.score) : '0'} pts</span>
              </div>
              <span>{currentChallenge?.['invited-id'] === session?.user?.id ? session?.user?.name : currentChallenge?.opponent?.name}</span>
            </div>
          </div>
        )}

        <div className="tab u-mb-0">
          {tabs.map((tab, index) => (
            <button
              className={`tab__link ${selectedTab === index && 'active'}`}
              onClick={() => setSelectedTab(index)}
              style={{ padding: '0.3em 0.7em' }}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* RESULTADO DA RODADA */}
        {selectedTab !== 8 && selectedTab > scores?.['scores_and_durations'].length - 1 && (
          <div className={styles['player-modal__result-section-content']}>
            <EmptyState
              type="data"
              text="Essa rodada ainda não começou"
              bgless={true}
            />
          </div>
        )}
        {selectedTab !== 8 && selectedTab <= scores?.['scores_and_durations'].length - 1 && (
          <>
            <div className={styles['player-modal__result-section-content']}>
              <h3 className={styles['player-modal__result-section-title']}>{currentScore?.answers[0]?.question?.content}</h3>
              <div className={styles['player-modal__result-section-items']}>
                <div className={styles['player-modal__result-section-item']}>
                  {creatorScore ? (
                    <>
                      <p className="u-mb-0">
                        <img
                          src={creatorScore?.correct ? checkIcon : xIcon}
                          alt={`ícone de ${creatorScore?.correct ? 'correto' : 'errado'}`}
                        />
                        {creatorScore?.duration?.toFixed(2)} segundos
                      </p>
                      <span className="badge">{formatDuration(creatorScore?.duration)}</span>
                    </>
                  ) : (
                    <span>Aguardando resposta</span>
                  )}
                </div>
                <div className={styles['player-modal__result-section-item']}>
                  {opponentScore ? (
                    <>
                      <p className="u-mb-0">
                        <img
                          src={opponentScore?.correct ? checkIcon : xIcon}
                          alt="ícone de correto"
                        />
                        {opponentScore?.duration?.toFixed(2)} segundos
                      </p>
                      <span className="badge ">{formatDuration(opponentScore?.duration)}</span>
                    </>
                  ) : (
                    <span>Aguardando resposta</span>
                  )}
                </div>
              </div>
            </div>
            {currentRound !== 8 && (
              <>
                <button
                  className="btn btn--primary btn--full"
                  onClick={handleNextQuestion}
                >
                  Próxima rodada
                </button>
                <span
                  className={styles['player-modal__result-section-link']}
                  onClick={() => setOpenPlayerModal(false)}
                >
                  Continuar mais tarde
                </span>
              </>
            )}
          </>
        )}

        {/* RESULTADOS GERAIS (VENCEDOR DEFINIDO) - ABA RESULTADOS */}
        {selectedTab === 8 && winner && (
          <>
            <div className={styles['player-modal__result-section-content']}>
              {scores?.['scores_and_durations'].map(item => (
                <div className={styles['player-modal__result-section-item-bg']}>
                  <h3>{item?.answers[0]?.question?.content}</h3>
                  <div>
                    <div>
                      <p className="u-mb-0">
                        <img
                          src={item?.answers[0]?.users?.[creatorId]?.correct === true ? checkIcon : xIcon}
                          alt="ícone de correto"
                        />
                        {item?.answers[0]?.users?.[creatorId]?.duration.toFixed(2)} segundos
                      </p>
                      <span className="badge">{formatDuration(item?.answers[0]?.users?.[creatorId]?.duration)}</span>
                    </div>
                    <div>
                      <p className="u-mb-0">
                        <img
                          src={item?.answers[0]?.users?.[opponentId]?.correct === true ? checkIcon : xIcon}
                          alt="ícone de correto"
                        />
                        {item?.answers[0]?.users?.[opponentId]?.duration.toFixed(2)} segundos
                      </p>
                      <span className="badge">{formatDuration(item?.answers[0]?.users?.[opponentId]?.duration)}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="btn btn--primary btn--full"
              onClick={() => setNewDuelModal(true)}
            >
              Novo Duelo
            </button>
          </>
        )}

        {/* AINDA NÃO HÁ VENCEDOR - ABA RESULTADOS*/}
        {selectedTab === 8 && !winner && (
          <div
            className={styles['player-modal__effect-background']}
            style={{ height: '300px' }}
          >
            <span className={styles['player-modal__effect-background-empty']}>?</span>
          </div>
        )}
      </div>
    </section>
  );
}
