const appName = process.env.REACT_APP_NAME;

const messages = {
  pt: {
    translations: {
      login: {
        login: 'Acesse sua conta',
        password: 'Senha:',
        forgotPass: 'Esqueceu sua senha?',
        forgotText: 'Digite o seu endereço de e-mail para que possamos enviar o link de redefinição de senha.',
        forgotTextDescription: 'Por favor, verifique sua caixa de entrada e a pasta de spam ou lixo eletrônico, caso não encontre o e-mail em sua caixa de entrada principal.',
        sendLink: 'Enviar link por email',
        createAccount: 'Criar uma nova conta',
        termsText: 'Ao clicar em "Criar conta", você concorda com os',
        userTerm: 'termos de uso',
        privacyTerm: 'política de privacidade'
      },
      createAccount: {
        createYourAccount: 'Crie sua nova conta',
        name: 'Nome:',
        cellphone: 'Celular:',
        selectSchool: 'Selecione uma escola',
        selectClass: 'Selecione uma turma',
        password: 'Senha:',
        buttonCreateAccount: 'Criar minha conta',
        loginWithMyAccount: 'Logar com a minha conta já existente'
      },
      header: {
        help: 'Precisa de ajuda?',
        //Anotações
        notes: 'Anotações',
        myNotes: 'Anotações privadas',
        classNotes: 'Anotações de turma',
        importantNotes: 'Anotações importantes.',
        createNewNote: 'Criar nova anotação',
        nameNote: 'Nome da anotação',
        newNote: 'Nova anotação',
        notesPlaceholder: 'Digite o nome da anotação...',

        languagePt: 'Português',
        languageEn: 'Inglês',
        //Notificações e mensagens
        notificationTitle: 'Notificações',
        notificationRead: 'Marcar todas como lidas'
      },
      helpCenter: {
        helpCenterTitle: 'Central de ajuda',
        helpTitle: 'Você está em nossa central de ajuda',
        frequentAsks: 'Dúvidas mais acessadas',
        //Nova senha
        needNewPass: 'Preciso de uma nova senha',
        sendLinkPass: 'Link Enviado',
        sendLinkPassText: 'Enviamos um link de alteração de senha para o seu e-mail de cadastro ',
        //Escolher notificações
        notificationChoose: 'Quero escolher minhas notificações',
        notificationChooseTitle: 'Escolher notificações',
        notificationChooseText: 'No menu <strong>Perfil</strong>, acesse Notificações e escolha as <strong>notificações</strong> que você quer receber.',
        notificationGo: 'Ir para notificações',

        contactUs: 'Entre em contato conosco',
        firstSteps: 'Primeiros passos',
        showMore: 'Mostrar mais',

        materials: 'Materiais',
        questions: 'Questões',
        projects: 'Projetos',
        classes: 'Aulas',
        activities: 'Ativ. complementares',
        generalMetrics: 'Métricas gerais',
        tasks: 'Tarefas',
        journey: 'Jornadas',
        quiz: 'Questionário'
      },
      helpFooter: {
        talkToUs: 'Ou fale com a gente',
        asksSend: 'Envie sua dúvida (responderemos em até 48h)',
        eventTitle: 'Título do evento',
        eventPlaceholder: 'Qual o assunto?',
        ask: 'Dúvida',
        askPlaceholder: 'Escreva sua dúvida aqui',
        emailSended: 'E-mail enviado',
        emailReceived: 'Recebemos sua mensagem. Entraremos em contato com você em breve.'
      },
      sideBar: {
        dashboard: 'Dashboard',
        feed: 'Feed',
        tasks: 'Tarefas',
        lms: 'Aulas',
        courses: 'Cursos',
        exams: 'Avaliações',
        materials: 'Materiais',
        questionBooks: 'Apostilas',
        activities: 'Ativ. complementares',
        projects: 'Projetos',
        journey: 'Jornada',
        study: 'Plano de Estudos',
        profile: 'Perfil',
        leave: 'Sair',
        liveClass: 'Aulas ao vivo',

        taxonomyAndMaterials: 'Ementas',
        coursesAndClass: 'Turmas',
        metrics: 'Métricas',
        participants: 'Participantes'
      },
      classes: {
        toggleInvitations: 'Convites',
        toggleImportSpreadsheet: 'Importar planilha',
        thIdentifier: 'Identificador',
        thImportCount: 'Quantidade de importações',
        thImportSpreadsheet: 'Planilha importada',
        thImportDate: 'Data da importação',
        thRegistrationCount: 'Quantidade de cadastros',
        thSendLink: 'Link enviado',
        thSendingDate: 'Data de envio',
        copyLink: 'Copiar link',
        users: 'usuários',
        buttonDetails: 'Detalhes',
        buttonGenerateLink: 'Gerar link',
        editClass: 'Editar Turma',
        searchClass: 'Buscar turma',
        accessLink: 'Link de acesso',
        accessLinkDescription: 'Permita que os estudantes façam o cadastro via link',
        associateClass: 'Associar a turma',
        tagImportSuccess: 'Importada com sucesso',
        tagimportError: 'Erro na importação',
        tagImportWait: 'Aguardando importação',
        buttonTemplateSpreadsheet: 'Planilha modelo',
        buttonImportSpreadsheet: 'Importar planilha',
        participants: 'Participantes'
      },
      dashboard: {
        projects: 'Projetos',
        activities: 'Atividades',
        perceptionQuest: 'Quest. de percepção',
        newProjectVersionToEvaluate: 'Nova versão de projeto para avaliar',
        newProjectVersionsToEvaluate: 'Novas versões de projetos para avaliar',
        activityToEvaluate: 'Atividade para avaliar',
        activitiesToEvaluate: 'Atividades para avaliar',
        answer: 'Resposta',
        answers: 'Respostas',
        accessProject: 'Acessar',
        accessActivities: 'Acessar',
        accessQuest: 'Acessar',
        latestUpdates: 'Últimas atualizações',
        unreadNotifications: 'Notificações não lidas',
        important: 'Importantes',
        completedModules: 'Módulos',
        completedLessons: 'Aulas'
      },
      events: {
        buttonAddEvent: 'Adicionar evento',
        titleCreateNewEvent: 'Criar um novo evento',
        toggleClass: 'Turma',
        togglePublic: 'Público',
        toggleCourse: 'Curso',
        eventDay: 'Dia do evento',
        eventHour: 'Horário do evento',
        eventTitle: 'Título do evento',
        placeholderEventDescription: 'Digite o nome do evento',
        placeholderAboutEvent: 'Descreva sobre o evento',
        toastCreating: 'Criando...',
        toastEventSuccessSaved: 'Evento salvo com sucesso!',
        toastEventSuccessCreated: 'Evento criado com sucesso!',
        toastEventErrorSaved: 'Erro ao salvar evento!',
        todayTasks: 'Tarefas de hoje'
      },
      feed: {
        mentorsAndTeachers: 'Mentores e Professores',
        textareaPlaceholder: 'Compartilhe algo',
        titleEvents: 'Eventos',
        buttonBackToFeed: 'Voltar para o feed'
      },
      tasks: {
        course: 'Tarefa do curso',
        class: 'Tarefa da turma',
        new: 'Nova tarefa',
        individual: 'Tarefa individual',
        dynamic: 'Tarefa dinâmica',
        lastWeek: 'Semana anterior',
        nextWeek: 'Próxima semana',
        typeLearn: 'Aprender',
        typeTraining: 'Treinar',
        titleTaskLabel: 'Título da tarefa',
        typeLabel: 'Tipo',
        placeholderTitleTask: 'Escreva o título da tarefa',
        placeholderType: 'Selecione...',
        noOptions: 'Sem opções',
        duration: 'Duração',
        hour: 'hora',
        hours: 'horas',
        description: 'Descrição',
        placeholderDescription: 'Descreva a tarefa para os estudantes',
        mattersRelated: 'Matérias relacionadas',
        mattersAdd: 'Adicione matérias...',
        buttonCreateTask: 'Criar tarefa',
        dailyLimit: 'Limite diário acumulado de 8h.',
        dailyLimitStudent: 'estudantes excederão o limite diário acumulado.',
        textReduceTime: 'Experimente reduzir o tempo da tarefa ou escolher outra data para que a maioria deles possa ser incluída.',
        buttonUndoTask: 'Desfazer tarefa',
        titleEditDescription: 'Clique na descrição da tarefa para editar',
        untitled: 'Sem título',
        toggleCourses: 'Cursos',
        toggleClass: 'Turmas',
        toggleStudents: 'Estudante',
        studentTaskTracking: 'Acompanhamento de tarefas do estudante'
      },
      lms: {
        lms: 'aulas',
        tableHeaderConcludedIn: 'Concluído em',
        toggleLms: 'Aulas',
        toggleCertifications: 'Certificados',
        toggleMaterialsAndForum: 'Materiais e fórum',
        toggleQuestionBooks: 'Exercícios',
        toggleActivity: 'Ativ. complementares',
        by: 'Por',
        requirements: 'Requisitos',
        tagRequirementsNot: 'Sem requerimentos',
        tagNewMessage: 'Nova mensagem',
        tagNew: 'Novo',
        buttonStartModule: 'Começar módulo',
        buttonContinue: 'Continue de onde parou',
        titleAboutModule: 'Sobre o módulo',
        titleWhatLearn: 'O que você aprenderá?',
        moduleCompleted: 'Módulo Concluído',
        moduleCompletedCongratulations: 'Parabéns! Você deu o seu melhor e concluiu o módulo',
        courseCompleted: 'Curso Concluído',
        courseCompletedCongratulations: 'Parabéns! Você deu o seu melhor e concluiu o curso',
        moduleCompletedCertificateReady: 'Seu certificado já está pronto',
        buttonGetCertificate: 'Obter certificado',
        titleQuestionLms: 'Responda à pergunta com base na aula que você está assistindo.',
        buttonTextSendingAnswer: 'Enviando resposta...',
        certificateTitle: 'Certificado de Conclusão',
        certificateText: 'Certificamos que <strong>{{userName}}</strong> concluiu com êxito o módulo <strong>{{moduleTitle}}</strong> do curso {{courseTitle}} no dia {{formattedDate}}, promovido pelo {{envName}} na plataforma da Estudologia.',
        certificateTextSmall: '*A duração do curso representa a duração de todos os vídeos no curso até o momento da conclusão mais recente.',
        certificateTextCourse: 'Certificamos que <strong>{{userName}}</strong> concluiu com êxito o curso <strong>{{courseTitle}}</strong> no dia {{formattedDate}}, promovido pelo {{envName}} na plataforma da Estudologia.',

        publish: 'Publicar',
        stepsModulesNameSideBar: 'Para começar, digite o nome do módulo e as descrições',
        stepsModulesObjectivesSideBar: 'Em seguida escreva objetivos de aprendizado para os estudantes',
        stepsModulesRequirementsSideBar: 'Escolha os requisitos para o módulo e relacione matérias',
        stepsModulesImageSideBar: 'Para finalizar, escolha uma imagem de capa e se desejar, insira um link para pesquisa de satisfação',
        newModule: 'Novo módulo',
        moduleName: 'Nome do módulo',
        placeholderModuleName: 'Insira o nome do módulo',
        shortDescription: 'Rápida descrição',
        placeholderShortDescription: 'Insira uma breve descrição',
        aboutModule: 'Sobre o módulo',
        placeholderAboutModule: 'Aqui você pode falar das características do módulo, metodologias, objetivos entre outras.',
        workload: 'Carga horária',
        typeAnumber: 'Digite um número',
        asideTextDescription: 'O módulo será disponibilizado para os <strong>estudantes de {{currentCourseTitle}}</strong>. Eles serão notificados quando o módulo for criado',
        studentsCourse: 'Estudantes no curso {{currentCourseTitle}}',
        distribution: 'Distribuição',
        objectiveModuleTitle: 'O que os estudantes estão aprendendo',
        objectiveModuleDescription: 'Escreva objetivos de aprendizado que os estudantes podem esperar ao final da disciplina',
        addAItem: 'Adicionar um item',
        placeholderModule1: 'Ex: Aprender a lógica de programação',
        placeholderModule2: 'Ex: Gerir o próprio negócio',
        placeholderModule3: 'Ex: Elaborar estratégias para ser assertivo nos estudos',
        asideTitleLearning: 'O que os estudantes aprenderão?',
        asideTextLearning:
          'O foco dessa sessão é ajudar o estudante a entender rapidamente os benefícios do módulo, sendo assim, nossa dica é: <strong>estruture os objetivos com frases curtas, diretas e usando verbos que indicam ação.</strong> Alguns exemplos:',
        asideRequirementsDescription:
          'Se o estudante precisa <strong>cursar obrigatoriamente módulos anteriores</strong> a este que você está criando, selecione-os com <strong>requisitos</strong>. O estudante só terá acesso ao módulo se já tiver completado os requisitos que você escolheu.',
        placeholderSelectRequirements: 'Selecione os requisitos',
        relatedSubjectDescription: 'A escolha das <strong>matérias relacionadas</strong> ajuda a construir a trilha de aprendizado dos estudantes.',
        previewModule: 'Pré visualizar módulo',
        whatWillLearnTitle: 'O que você aprenderá?',
        requirementModuleTitle: 'Há algum requisito para o módulo?',
        modulesOf: 'Módulos de',
        printCertificate: 'Imprimir certificado'
      },
      exams: {
        toggleExams: 'Provas',
        toggleMyResults: 'Meus resultados',
        statusInAnalysis: 'Em análise',
        statusInApproved: 'Aprovado',
        statusInDisapproved: 'Reprovado',
        statusAcceptingAnswers: 'Aceitando respostas',
        statusUnrealized: 'Não realizada',
        statusDone: 'Realizada',
        labelPublishedAt: 'Aplicação em:',
        accessExam: 'Acessar prova',
        examAnswer: 'Gabarito da prova',
        inProgress: 'Em andamento',
        thStats: 'Status',
        thExams: 'Avaliações',
        thCourse: 'Curso',
        thClass: 'Turma',
        thResults: 'Resultado',
        thResolutionDate: 'Data da resolução',
        thScore: 'Nota',
        thActions: 'Ações',
        thAvarageScore: 'Nota média',
        thParticipants: 'Participantes',
        thParticipant: 'Participante',
        tableHeaderQuestionId: 'ID da questão',
        tableHeaderAnswerKey: 'Gabarito oficial',
        tableHeaderStudentResponse: 'Resposta do estudante',
        tableHeaderStudentResult: 'Resultado do estudante',
        tableHeaderOverallPerformace: 'Desempenho global',
        examBannerDescription: 'Abaixo você poderá encontrar algumas orientações do seu professor. Leia-as atentamente e boa prova!',
        examBannerDescriptionWaterAndBathroom: 'Bebeu água? Foi ao banheiro?',
        titleDescription: 'Descrição e orientações',
        from: 'De',
        at: 'às',
        to: 'a',
        and: 'e',
        hoursDuration: '<strong>{{hours}}</strong> horas de duração',
        hourDuration: '<strong>{{hours}}</strong> hora de duração',
        questions: 'questões',
        titleMaterialsDistribuition: 'Distribuição das matérias',
        sendExam: 'Entregar prova',
        next: 'Próxima',
        previous: 'Anterior',
        reply: 'Responder',
        replyAndFinish: 'Responder e finalizar',
        submitExam: 'Finalizar avaliação',
        time: 'Tempo',
        subtitleSubmitExam: 'Você quer finalizar e enviar suas respostas?',
        submitedQuestions: 'questões respondidas',
        titleSendAnswers: 'Respostas enviadas',
        textCongratulations: 'Parabéns! Você terminou de responder',
        textAbleToPerformace: 'Você poderá acompanhar o resultado do seu desempenho após às ',
        textResultPerformace: 'Você pode acompanhar o resultado clicando no botão abaixo',
        examMinutes: 'minutos de prova',
        answeredQuestions: 'Questões respondidas',
        minOfDay: 'min do dia',
        closeExam: 'Sair',
        titleFinishPageSendAnswers: 'Suas respostas foram enviadas!',
        congratulationsFinishAnswers: 'Parabéns você terminou de responder.',
        thanksForParticipating: 'Obrigado por participar.',
        buttonBackToDashboard: 'Voltar para o dashboard',
        questionTitle: 'Questão',

        toggleQuestionBank: 'Banco de questões',
        toggleQuestionBooks: 'Exercícios',
        toggleSimulations: 'Simulados',
        toggleResultExams: 'Resultados',
        buttonNewQuestion: 'Nova questão',
        tableHeaderType: 'Tipo',
        tableHeaderQuestion: 'Questão',
        tableHeaderArea: 'Área',
        tableHeaderPublication: 'Publicação',
        tableHeaderExclusivity: 'Exclusividade',
        tableHeaderAuthorship: 'Autoria',
        labelQuestionType: 'Tipo de questão',
        selectMultipleChoice: 'Múltipla escolha',
        selectTrueOrFalse: 'Certo ou errado',
        trueOrFalse: 'Certo / Errado',

        titleNewQuestion: 'Nova questão',
        subtitleNewQuestion: 'Para começar escolha um nome, selecione uma a área de conhecimento e matérias para relacionar à questão.',
        formTitleQuestionName: '*Nome da questão',
        placeholderQuestionName: 'O nome aparecerá apenas para você',
        tooltipContent: 'O nome da questão é para auxiliar na sua identificação. Os estudantes não verão essa informação',

        stepsTextName: 'Comece escolhendo o nome, a área e as matérias relacionadas',
        stepsTextInfo: 'Descreva as informações sobre a questão',
        stepsTextType: 'Escolha o tipo e elabore as respostas',
        stepsTextVisibility: 'Por fim, escolha quando e onde a questão deve ser vista pelos estudantes',

        subtitleMotivatorText: 'Desenvolva o texto motivador, o enunciado e, se houver, a imprenta.',
        formTitleMotivatorText: 'Texto motivador',
        formTitleStatementQuestion: 'Enunciado da questão',
        pInsertMotivatingText: 'Insira o texto motivador aqui!',
        pInsertStatement: 'Insira o enunciado aqui!',
        titlePress: 'Imprenta',
        placeholderPress: 'Os estudantes não verão essa informação',

        subtitleTypeQuestion: 'Escolha o tipo da questão, escreva o texto das alternativas e marque a alternativa correta.',
        titleAlternatives: '*Alternativas',
        descriptionMultipleChoice: 'Escreva o texto das alternativas e marque a correta',
        buttonAddAlternative: 'Adicionar alternativa',
        descriptionTrueOrFalse: '*Marque a opção correta para a questão',
        alternativeTrue: 'Certo',
        alternativeFalse: 'Errado',

        subtitleExclusivity: 'Por fim selecione publicação e a exclusividade da questão. Questões exclusivas serão mostradas apenas em provas e simulados.',
        titlePublicationExclusivity: '*Publicação e exclusividade da questão',
        labelAfterCreate: 'Publicar após a criação',
        labelExclusiveExams: 'Questão exclusiva para avaliações',
        buttonPreview: 'Pré-visualizar',
        correctAnswer: 'Resposta correta:',
        textAsideQuestion: 'Questão',
        textAsidePublished: 'publicada',
        textAsideNotPublished: 'não publicada',
        textAsideExclusive: 'exclusiva',
        textAsideNotExclusive: 'não exclusiva',

        newQuestionBook: 'Nova apostila',
        questionBookName: 'Nome do exercício',
        placeholderQuestionBookName: 'Insira o nome da apostila',
        selectCourse: 'Selecione um curso',
        loading: 'Carregando...',
        selectModule: 'Selecione um módulo.',
        chooseSubject: 'Escolha as matérias que irão compor a apostila',
        stepsTextNameQuestionBook: 'Escolha um nome e associe a apostila a um curso e seu respectivo módulo',
        stepsTextAddSubjects: 'E adicione matérias.',
        getSubject: 'Buscar matéria...',
        examSubjects: 'Matérias da prova',
        publish: 'Publicar',
        selectExamsSubject: 'Selecione as matérias da prova',
        addQuestionTitle: 'Adicionar questões de',
        placeholderSearchQuestion: 'Buscar questão...',
        tagUnreleasedQuestion: 'Questão inédita',

        newSimulation: 'Novo simulado',
        nameSimulation: 'Nome do Simulado',
        placeholderSimulationName: 'Escreva o nome do simulado',
        descriptionRecommendations: 'Descrição ou recomendações para os estudantes',
        titleAsideBarSimulations: 'Estudantes de {{currentCourseTitle}}',
        titleAsideBarDescriptions: 'A prova será disponibilizada para os <strong>estudantes de {{currentCourseTitle}}</strong>. Eles serão notificados quando ela for marcada',
        titleAsideBarTitle: 'Estudantes no curso {{currentCourseTitle}}',
        titleAsideBarStartEnd: 'Ínício e fim da avaliação',
        stepsSimulationCourse: 'Associe o simulado a um curso, escolha um nome e dê instruções os estudantes',
        stepsSimulationName: 'Adicione as matérias',
        stepsSimulationDetails: 'Selecione a duração, a nota de corte e o fator de correção',

        detailsDescription: 'Faça as configurações da avaliação e em seguida, selecione as questões.',
        limitTimeTitle: 'Tempo limite para a entrega',
        startDateTitle: 'Início da avaliação',
        endDateTitle: 'Fim da avaliação',
        startHourTitle: 'Horário do início',
        endHourTitle: 'Horário do fim',
        passingScoreTitle: 'Nota de corte',
        correctionFactorTitle: 'Fator de correção',
        textAsideNoCorrectFactor: 'Sem Fator de correção',
        textAsideWrongCancelRight: 'Uma errada anula uma certa',
        titleWeekToNextSimulationTitle: 'Semanas para o próximo simulado',
        placeholderExample: 'Exemplo',

        buttonCreateExam: 'Criar prova',
        buttonManageExams: 'Gerenciar provas',
        publishExam: 'Publicar prova',

        toggleModule: 'Módulo',
        toggleClass: 'Turma',
        newExam: 'Nova prova',
        nameExam: 'Nome da prova',
        placeholderExamName: 'Escreva o nome da prova',

        stepsTextExamsCourse: 'Comece associando a prova a um curso, turma ou módulo',
        stepsTextExamsName: 'Escolha um nome, instrua os estudantes e adicione matérias',
        stepsTextExamsDuration: 'Selecione a duração, a nota de corte e o fator de correção',

        participation: 'Participação',
        aproved: 'Aprovados',
        globalAvarage: 'Média global',
        questionNumber: 'Nº de Questões',
        avarageTime: 'Tempo médio',

        exams: 'Provas',
        simulations: 'Simulados',
        examsSimulations: 'Provas e Simulados',
        questionExclusivity: 'Exclusividade da questão',
        chooseExclusivity: 'Escolha a exclusividade da questão',
        nonExclusive: 'Não exclusiva',
        exclusiveToExams: 'Exclusiva para provas',
        exclusiveToSimulations: 'Exclusiva para simulados',
        exclusiveToExamsAndSimulations: 'Exclusiva para provas e simulados',
        questionPublishing: 'Publicação da questão',
        shift: 'Turno',
        seriesCourse: 'Série/Curso',
        classModule: 'Turma/Módulo',
        subject: 'Disciplina'
      },
      materials: {
        search: 'Buscar',
        buttonAccessMaterials: 'Acessar materiais',
        lastChange: 'Última alteração:',
        buttonAccessMaterial: 'Acessar material',
        buttonAccessActivity: 'Acessar atividade',
        exploreMaterials: 'Veja outros materiais do módulo'
      },
      questionBooks: {
        toggleQuestionBooks: 'Exercícios',
        toggleResultExercises: 'Resultado dos exercícios ',
        buttonNewQuestionBook: 'Novo exercício',
        buttonAccessQuestionBook: 'Acessar exercícios',
        publishIn: 'Publicado em:',
        today: 'Hoje',
        weekCurrent: 'Semana Atual',
        monthLast: 'Último mês',
        monthThree: '3 meses',
        answeringAverageTime: 'Média móvel de tempo respondendo',
        exploitation: 'Aproveitamento',
        mistakes: 'Erros',
        hits: 'Acertos',
        lastDay: 'dia anterior',
        lastWeek: 'semana passada',
        lastMonth: 'mes passado',
        less: 'a menos',
        more: 'a mais',
        what: 'que o',
        labelNeverAnswered: 'Questões que nunca respondi',
        labelNeverGot: 'Questões que nunca acertei',
        labelGotAndWrong: 'Questões que acertei e errei',
        buttonPause: 'Pausar',
        labelQuestionBookName: 'Nome do caderno de questões',
        labelMyInteractions: 'Minhas interações',
        buttonCreateQuestionBook: 'Criar caderno de questões',

        //back-end
        medium: 'média',
        hard: 'difícil',
        easy: 'fácil'
      },
      activity: {
        emptyInfo: 'Nenhuma informação disponível no momento.',
        comparisonTitleCourses: 'Comparativo de atividades enviadas entre cursos',
        comparisonTitleClasses: 'Comparativo de atividades enviadas entre turmas',
        studentListing: 'Listagem de estudantes',
        buttonSaveActivity: 'Salvar atividade',
        submitEvaluation: 'Enviar avaliação',
        activityOf: 'Atividade de {{activityStudent}}',
        teacherEvaluation: 'Avaliação do Docente',
        placeholderTeacherEvaluation: 'Escreva sua avaliação aqui',
        activityTitle: 'Atividade complementar',
        evaluate: 'Avaliar',
        evaluateActivities: 'Avaliar atividades',
        evaluateActivity: 'Avaliar atividades',
        placeholderActivityTitle: 'Escreva aqui o título da atividade',
        waitingEvaluation: 'Aguardando avaliação',
        evaluated: 'Avaliada',
        notSubmited: 'Não enviada',
        evaluationCompleted: 'Avaliação realizada',
        viewEvaluation: 'Ver avaliação',
        totalSubmissions: 'Entregas totais',
        placeholderActivityDescription: 'Descreva a atividade aqui',
        newTitle: 'Nova',
        editingTitle: 'Edição de',
        buttonAccess: 'Acessar',
        answers: 'Respostas',
        titleResponseToday: 'Respostas de hoje:',
        titleStudentAnswer: 'Resposta do Estudante',
        titleActivityDescription: 'Descrição da Atividade',
        saveAndLeave: 'Salvar e sair',
        missed: 'Você errou',
        right: 'Acertou',
        correct: 'Acertou',
        wrong: 'Errou',
        blank: 'Em branco',
        useOf: 'Aproveitamento de',
        warningText: 'Os resultados de hoje serão zerados à meia-noite. <br></br>Caso não haja interação por 5 minutos, a pausa ocorrerá automaticamente.',
        buttonWarning: 'Não foi desta vez, gabarito:',
        titleTeacherEvaluation: 'Avaliação do Professor',
        in: 'Em',
        sendFiles: 'Arquivo enviado',
        sendUrl: 'URL enviada',
        placeholderUploadFile: 'Clique aqui para inserir um arquivo',
        placeholderSentUrl: 'Digite a URL',
        toggleFile: 'Arquivo',
        placeholderWriteComment: 'Escreva seu comentário aqui',
        buttonBackToActivity: 'Voltar a atividade complementar',
        buttonBackToCourse: 'Voltar ao curso',
        warningSubmit: 'Tem certeza que deseja enviar a atividade? Essa ação não poderá ser desfeita.',
        stepsActivityName: 'Escolha o nome, a destinação, e as matérias relacionadas',
        stepsActivityAbout: 'Em seguida fale sobre a atividade',
        stepsActivityDeadline: 'Ao final, determine a data, a quantidade de horas/aula e a obrigatoriedade da atividade',
        newActivityTitle: 'Nova Atividade complementar',
        newActivityDescription: 'Para começar escolha o nome da atividade, para quem deve ser enviada e as matérias relacionadas',
        activityName: 'Nome da atividade',
        placeholderActivityName: 'Escreva o nome da atividade',
        aboutActivityDescription: 'Aqui você pode falar sobre a atividade e dar instruções aos estudantes de como eles devem executá-la.',
        aboutActivity: 'Sobre a atividade',
        periodDescription: 'Informe a data que a atividade ficará disponível para resolução dos estudantes',
        periodName: 'Atividade deve ser enviada a partir de:',
        hoursLesson: 'Horas/Aula',
        mandatoryActivity: 'Atividade obrigatória para o prosseguir no módulo',
        associateToLesson: 'Associe a uma aula',
        selectLesson: 'Escolha uma aula',

        //back-end:
        activity_not_submitted: 'Atividade não enviada',
        awaiting_submission: 'Aguardando envio',
        activity_evaluated: 'Atividade avaliada',
        activity_submitted: 'Atividade enviada'
      },
      projects: {
        addManager: 'Adicionar gestor',
        placeholderManager: 'Digite o nome do gestor',
        placeholderProfile: 'Digite o nome do novo perfil',
        managersDescription: 'Adicione gestores para auxiliar no projeto',
        sidebarGroups: 'Grupos',
        sidebarGroup: 'Grupo',
        sidebarIndividual: 'Individual',
        sidebarCustomized: 'Personalizado',
        projectDistribution: 'Distribuição no projeto',
        individualDescription: 'Um projeto para cada participante',
        customizedDescription: 'Escolha quem fará parte do projeto',
        stepsParticipants: 'Informe a quantidade de participantes',
        enterNumberParticipants: 'Informe a quantidade de participantes dos grupos',
        tipRandomParticipants: 'Os participantes serão escolhidos aleatoriamente',
        groupStudentsDescriptionCount: '{{studentsLength}} grupos <span>com {{groupSize}} participantes</span>',
        buttonCreateGroups: 'Criar grupos',
        stepsManagers: 'Adicione gestores',
        labelRandomGroup: 'Grupos aleatórios',
        labelIndividual: 'Individual',
        labelCustomized: 'Personalizado',
        projectOrganization: 'Organização do projeto',
        placeholderAboutProject: 'Aqui você pode falar das características do projeto, metodologias, objetivos entre outros.',
        placeholderAbout: 'Aqui você pode falar das características, metodologias, objetivos entre outros.',
        previewProject: 'Pré visualizar projeto',
        projectName: 'Nome do projeto',
        placeholderProjectName: 'Digite o nome do projeto',
        asideProjectDescription: 'O projeto será disponibilizado para os <strong>estudantes de {{currentCourseOrClassTitle}}</strong>. Eles serão notificados quando o projeto for criado',
        stepsCourseClass: 'Primeiro selecione um curso ou turma para associar ao novo projeto',
        stepsName: 'Em seguida digite o nome e adicione uma descrição',
        stepsImage: 'Escolha uma imagem',
        stepsOrganization: 'Escolha como como você quer organizar o projeto',
        selectParticipants: 'Selecione participantes',
        newEvent: 'Novo evento',
        placeholderStudentName: 'Digite o nome do estudante',
        placeholderSearchProjects: 'Pesquisar projetos',
        newProjects: 'Novo projeto',
        toggleGroups: 'Grupos',
        toggleIndividuals: 'Individuais',
        toggleCustomized: 'Personalizados',
        pendingEvaluation: 'Avaliação pendente',
        accessProject: 'Acessar projetos',
        createdIn: 'Criado em',
        createdAt: 'Criada dia',
        versionProject: 'Versão',
        titleProjectParticipants: 'Participantes do projeto',
        titleProjectSchedule: 'Cronograma do projeto',
        participants: 'Participantes',
        buttonSendNewVersion: 'Enviar uma nova versão',
        titleCreateProject: 'Criação do projeto',
        titleFullDay: 'Dia inteiro',
        titleProjectTeacher: 'Professores do projeto',
        titleProjectStudents: 'Estudantes do projeto',
        titleProjectManagers: 'Gestores do projeto',
        titleTeachers: 'Professores',
        titleStudents: 'Estudantes',
        warningDeleteProjectVersionTitle: 'Deseja mesmo excluir esta versão?',
        warningDeleteCannotBeUndoneText: 'Esta ação não pode ser desfeita.',
        warningDeleteCommentTitle: 'Deseja mesmo excluir este comentário?',
        warningDeleteFileTitle: 'Deseja mesmo excluir este arquivo?',
        warningDeleteTask: 'Deseja mesmo excluir esta tarefa?',
        warningDeleteQuestionBook: 'Deseja mesmo excluir esta apostila?',
        warningDeleteClass: 'Deseja mesmo excluir esta turma?',
        warningDeleteStudent: 'Deseja mesmo excluir esta estudante?',
        warningDeleteQuestion: 'Deseja mesmo excluir esta questão?',
        warningDeleteActivity: 'Deseja mesmo excluir esta atividade complementar?',
        warningDeleteExam: 'Deseja mesmo excluir esta prova?',
        warningDeleteSubject: 'Deseja mesmo excluir esta matéria?',
        warningDeleteLesson: 'Deseja mesmo excluir esta aula?',
        warningDeleteMatter: 'Deseja mesmo excluir esta disciplina?',
        warningDeleteMaterialsFolder: 'Deseja mesmo excluir esta pasta de materiais?',
        warningDeleteFolder: 'Deseja mesmo excluir esta pasta?',
        warningDeleteCourse: 'Deseja mesmo excluir este curso? Gostaria de te lembrar que tudo relacionado a este curso será arquivado.',
        warningDeleteTeacher: 'Deseja mesmo excluir este professor?',
        warningDeleteEvent: 'Deseja mesmo excluir este evento?',
        warningDeleteModule: 'Deseja mesmo excluir este módulo?',
        warningDeleteMaterial: 'Deseja mesmo excluir este material?',
        warningDeleteParticipant: 'Deseja mesmo excluir este participante?',
        warningDeleteProfile: 'Deseja mesmo excluir este perfil?',
        warningDeleteProject: 'Deseja mesmo excluir este projeto?',
        warningDeleteQuest: 'Deseja mesmo excluir este questionário de percepção?',
        warningDeleteStudentFromProject: 'Tem certeza que deseja excluir esse estudante desse projeto?',
        updatedIn: 'Atualizado em',
        in: 'de',
        by: 'por'
      },
      journey: {
        isActive: 'Ativo',
        isInactive: 'Inativo',
        toggleMetrics: 'Métricas',
        toggleMedals: 'Medalhas',
        thCourses: 'Cursos',
        thClasses: 'Turmas',
        thLastLmsWatched: 'Última aula assistida',
        thLastAccess: 'Último acesso',
        conclusion: 'Conclusão',
        studentCourse: 'Cursos do estudante',
        studentClasses: 'Turmas do estudante',
        monitoringCoursesTitle: 'Acompanhamento dos cursos',
        averageAccessStudentTitle: 'Média móvel comparativa do estudante',
        lastWeek: 'Última semana',
        lastmonth: 'Último mês',
        threeMonth: '3 meses',
        totalAcess: 'Acessos totais',
        totalTasks: 'Tarefas totais',
        materials: 'Materiais',
        selectMatterText: 'Selecione a matéria para mais detalhes do rendimento.',
        notCompleted: 'Não concluída',
        completed: 'Concluída',
        accessesInClasses: 'Acesso nas aulas',
        medals: 'Medalhas',
        accessedIn: 'Acessado em'
      },
      profile: {
        toggleProfileInfo: 'Informações de perfil',
        toggleNotifications: 'Notificações',
        toggleSecurity: 'Segurança',
        labelFullName: 'Nome completo',
        labelPhone: 'Telefone',
        labelEmail: 'E-mail',
        bio: 'Bio',
        disclaimerLGPD: 'Estamos adequados à LGPD e boas práticas sobre a divulgação de dados e afins',
        typeYourPass: 'Digite sua senha',
        typeYourNewPass: 'Digite sua nova senha',
        confirmNewPass: 'Confirme sua nova senha',
        passMustMatch: 'As senhas precisam ser iguais',
        confirmYourPass: 'Confirme sua senha',
        newPass: 'Nova senha',
        currentPass: 'Senha atual',
        wantSmsNotification: 'Quero receber notificações por SMS/WhatsApp.',
        wantEmailNotification: 'Quero receber notificações por email.',
        notificatedByTitle: 'Você é notificado por e-mail e SMS/WhatsApp quando:',
        switchCreatedEvent: 'Um evento é criado.',
        switchNewMaterial: 'Um novo material é adicionado.',
        switchNewPost: 'Há novo post no fórum.',
        switchNewActivity: 'É criada uma nova atividade complementar.',
        switchNewTask: 'É criada uma nova tarefa.',
        switchAddNewProject: 'Você é adicionado em um novo projeto.'
      },
      taxonomyAndMaterials: {
        toggleTaxonomies: 'Taxonomias',
        toggleMaterials: 'Materiais',
        buttonAddTaxonomy: 'Adicionar taxonomia',
        createTaxonomy: 'Criar taxonomia',
        placeholderTaxonomyName: 'Digite o nome da taxonomia',
        placeholderNewTaxonomyName: 'Digite o novo nome da taxonomia',
        buttonCreateNewTaxonomy: 'Criar nova taxonomia',
        buttonEditTaxonomy: 'Editar taxonomia',
        warningDeleteTaxonomy: 'Deseja mesmo excluir esta taxonomia?',
        buttonNewFolder: 'Nova pasta',
        createMaterialFolder: 'Criar pasta de material',
        folderName: 'Nome da pasta',
        placeholderFolderName: 'Digite o nome da pasta',
        buttonNewMaterial: 'Novo material',
        addMaterialTitle: 'Adicionar material',
        fileName: 'Nome do arquivo',
        placeholderFileName: 'Digite o nome do arquivo',
        placeholderSelectMaterial: 'Selecione um material',
        relatedCourses: 'Cursos relacionados',
        relatedClass: 'Turmas relacionadas'
      },
      courses: {
        toggleMyCourses: 'Meus cursos',
        createNewCourse: 'Criar um novo curso',
        createCourseDescription: 'Crie um novo curso e em seguida selecione as matérias relacionadas',
        courseTitle: 'Título do curso',
        placeholderCourseTitle: 'Digite o título do curso',
        buttonCreateCourse: 'Criar novo curso',
        buttonNewCourse: 'Novo curso',
        buttonNewClass: 'Criar nova turma',
        newClass: 'Nova turma',
        newClassDescription: 'Crie uma nova turma e em seguida selecione os participantes',
        className: 'Nome da turma',
        placeholderClassName: 'Digite o nome da turma',
        relatedClasstoCourse: 'Relacione a turma a um curso',
        classAvailabilityPeriod: 'Período de disponibilidade da turma',
        managers: 'Gestores',
        addParticpants: 'Adicionar participantes',
        deleteParticipants: 'Remover participante',
        searchParticipants: 'Buscar participantes...',
        placeholderParticipantName: 'Digite o nome do participante',
        editCourse: 'Editar Curso',
        courseTitle: 'Título do curso',
        students: 'Estudantes',
        thStudent: 'Estudante',
        thRegistrationMethod: 'Forma de cadastro',
        classes: 'Turmas',
        lastUpdate: 'Última atualização',
        accessIndex: 'Índice de acesso',
        evaluations: 'Avaliações',
        exams: 'Avaliações',
        activities: 'Atividades',
        projects: 'Projetos',
        questionBooks: 'Apostilas',
        classesOf: 'Turmas de',

        //Criação de cursos

        routeName: 'Escolha um nome',
        routeAbout: 'Fale sobre o curso',
        routeImage: 'Para finalizar, escolha uma imagem que auxilia a identificação do curso',
        placeholderCourseName: 'Escreva o nome do curso',
        aboutCourse: 'Sobre o curso',
        aboutCoursePlaceholder: 'Utilize esse espaço para falar das características, metodologias e outras informações relevantes que desperte o interesse dos estudantes.',
        coverImage: 'Imagem de capa',
        previewCourse: 'Pré-visualizar curso',
        asideTextDescription: 'Uma boa imagem de capa é essencial para identificar e criar interesse nos estudantes. Você pode usar um banner promocional ou fotografias para ilustrar o módulo.',
        observations: 'Observações:',
        asideTextDimensions:
          '<strong>Dimensões da imagem de capa:</strong> Escolha por imagens com dimensões de 1020x252 pixels e formato em .jpg, .jpeg e .png. As imagens que não estiverem dentro dessas dimensões, serão ajustadas para melhor visualização em celulares e computadores <br />',
        asideTextLegibility: '<strong>Adequação para legibilidade:</strong> Para dar boa legibilidade ao texto (as informações sobre o módulo) que irá compor a imagem de capa, vamos ajustar a imagem escurecendo levemente como no exemplo abaixo:',
        asideTextAltTip: 'dica: acontece um escurecimento da imagem',
        asideImageSecurity: 'dica: área de segurança da imagem',
        AsideTextTip: '<strong>Dica:</strong> Se você está a procura de boas imagens, pesquise em sites de bancos de imagens, em geral, elas têm boa qualidade e bom enquadramento.',
        toggleDisciplineLessons: 'Disciplinas e aulas',
        toggleActivity: 'Atividades complementares',
        toggleMaterials: 'Materiais',
        toggleQuestionBooks: 'Exercícios',
        toggleAbout: 'Sobre',
        toggleFiles: 'Arquivos'
      },
      lessons: {
        lesson: 'Aula',
        isLessonMandatory: 'A aula será obrigatória para prosseguir no módulo?',
        stepsLessonName: 'Comece nomeando a nova aula e relacionado matérias',
        stepsLessonVideo: 'Faça o upload da videoaula',
        stepsLessonVideoOrSlide: 'Faça o upload da videoaula ou slide',
        stepsLessonQuestions: 'Adicione perguntas em tempo real',
        lessonVideoDescription: 'Adicione uma videoaula. Este passo é fundamental para o prosseguimento da aula.',
        titleLinkVideo: 'Link da videoaula',
        titleLinkVideoOrSlide: 'Link da videoaula / slide',
        placeholderSetLink: 'Insira o link da videoaula',
        errorInvalidLink: 'Link do vídeo inválido',
        realtimeQuestionsTitle: 'Perguntas em tempo real',
        realtimeQuestionsDescription: 'Estimule a participação dos estudantes com perguntas durante a aula. Mantenha o interesse e garanta uma compreensão efetiva.',
        setTimeQuestionTitle: 'Escolha o momento em que a pergunta deve aparecer'
      },
      nps: {
        buttonNewQuiz: 'Novo questionário',
        newQuiz: 'Novo questionário de percepção',
        resolutionPeriod: 'Período disponível para resolução',
        quizTitle: 'Título do questionário',
        placeholderQuizTitle: 'Digite o título do caderno de questões',
        placeholderDescription: 'Descrição do questionário',
        stars: 'Estrelas',
        star: 'estrela',
        openReponses: 'Respostas abertas',
        accessQuest: 'Acessar questionário',
        everythingBeingSaving: 'Tudo está sendo salvo. As alterações são salvas em background pelo sistema.',
        buttonAddQuestions: 'Adicionar perguntas',
        buttonFinishView: 'Finalizar e visualizar',
        buttonIntervention: 'Proposta de intervenção',
        toggleAll: 'Geral',
        toggleStudentList: 'Lista de estudantes',
        graphicScale: 'Escala gráfica',
        question: 'Pergunta',
        questions: 'perguntas',
        stronglyDisagree: 'Discordo totalmente',
        partiallyDisagree: 'Discordo parcialmente',
        neitherAgreeNorDisagree: 'Nem discordo, nem concordo',
        partiallyAgree: 'Concordo parcialmente',
        stronglyAgree: 'Concordo totalmente',
        setYourQuestion: 'Insira sua pergunta',
        writeProposal: 'Escreva aqui sua proposta',
        quizDisplayCard: 'É dessa forma que o questionário será mostrado para os estudantes',
        buttonViewInterventionProposals: 'Ver propostas de intervenção',
        totalResponses: 'Total de respostas',
        totalQuestions: 'Total de perguntas',
        filterResponseSent: 'Resposta enviada',
        yearCourse: 'Ano/Curso'
      },
      metrics: {
        blankLabelSelect: 'Todos os cursos',
        projectsInProgress: 'Projetos em andamento',
        metricsTitle: 'Você está em nosso painel de métricas',
        metricsDescription: 'Acompanhe os índices de desempenho dos estudantes através de painéis de controle com indicadores individuais e coletivos.',
        selectMetricsParagraph: 'Selecione um painel para acessar as métricas',
        titleClasses: 'Aulas',
        subtitleClasses: 'Acesse a evolução dos estudantes nos módulos',
        titleEngagement: 'Engajamento',
        subtitleEngagement: 'Acesse a interação dos estudantes',
        totalStudents: 'Total de estudantes',
        student: 'Estudante',
        stats: 'Status',
        distributionStudents: 'Distribuição dos estudantes do curso',
        distributionStudentsByCourse: 'Distribuição dos estudantes por curso',
        distributionEnrolledStudentsModule: 'Distribuição dos estudantes  inscritos no módulo',
        byProgress: 'por progresso',
        byModule: 'por módulo',
        students: 'Estudantes',
        averageClassAttendance: 'Média de acesso nas aulas',
        perception: 'Percepção',
        accessesLastWeek: 'Acessos na última semana',
        tooltipsLikes: 'Número total de likes no feed relacionado a turma',
        tooltipsComments: 'Número total de comentários do estudante no feed e nos fóruns das aulas relacionado ao curso',
        tooltipsMaterials: 'Número total de acessos a todos os materiais relacionado ao curso',
        tooltipsLessons: 'Número total de aulas acessadas no curso',
        tooltipsModules: 'Número total de módulos concluídos no curso',
        createdProjects: 'Projetos criados',
        approvedProjects: 'Projetos aprovados',
        registeredProjects: 'projetos cadastrados',
        submittedProjects: 'Projetos Enviados',
        registeredActivities: 'atividades cadastradas',
        additionalActivities: 'Atividades complementares',
        totalTasks: 'tarefas totais para o período',
        performanceSubject: 'Rendimento por assunto',
        accessVariation: 'Var. de acesso'
      },
      participants: {
        buttonRegister: 'Cadastrar participante',
        toggleAdminProfiles: 'Perfis administrativos',
        toggleNotifications: 'Notificações',
        profile: 'Perfil',
        labelFullName: 'Nome completo',
        phone: 'Telefone',
        message: 'Mensagem',
        placeholderSendMessage: 'Se desejar, envie uma mensagem para o participante',
        buttonEdit: 'Editar',
        buttonSendEmail: 'Enviar e-mail de cadastro',
        labelChooseProfile: 'Escolha um perfil',
        tagRegistrationSent: 'Cadastro enviado'
      },
      notFoundPage: {
        titleOhNo: 'Essa não!',
        Description404: 'Pedimos desculpas pois não conseguimos encontrar a página que você estava procurando.',
        buttonBackToPlatform: 'Voltar para plataforma'
      },
      datesForm: {
        formattedDate: ' {{day}} de {{monthName}} de {{year}}',
        formatDate: 'DD/MM/YYYY',
        formatDayMonth: 'DD/MM'
      },
      weekDays: {
        sunday: 'Domingo',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado'
      },
      monthName: {
        january: 'janeiro',
        february: 'fevereiro',
        march: 'março',
        april: 'abril',
        may: 'maio',
        june: 'junho',
        july: 'julho',
        august: 'agosto',
        september: 'setembro',
        october: 'outubro',
        november: 'novembro',
        december: 'dezembro'
      },
      onboarding: {
        notDisplay: 'Não mostrar novamente',
        titleFeed: 'Como usar o Menu Feed',
        titleActivities: 'Como usar o Menu Atividades complementares',
        titleDashboard: 'Como usar o Menu Início',
        titleLms: 'Como usar o Menu Aulas',
        titleMaterials: 'Como usar o Menu Materiais',
        titleTasks: 'Como usar o Menu Tarefas',
        titleProjects: 'Como usar o Menu Projetos',
        titleQuestionBooks: 'Como usar o Menu Exercícios',
        titleAuditorium: 'Como usar o Menu Monitoria',
        titleExamsResolution: 'Como usar o Menu Provas',
        titleMockQuestionsBook: 'Como usar o Menu Simulados',
        titleStudyPlan: 'Como usar o Menu Guia de Estudos',
        titleShowUser: 'Como usar o Menu Desempenho',
        titleCourse: 'Como usar o Menu Cursos',
        titleProfile: 'Como usar o Menu Perfil',
        topBar: 'Barra superior',
        topBarDescription: 'Na barra superior temos a mensagem de boas vindas, botão para trocar de ambiente (caso o usuário tenha permissão), botão de Anotações e os ícones de busca, mensagens e notificações.',
        selectCourseOrCertificates: 'Selecione Cursos ou Certificados',
        selectCourseOrCertificatesDescription: 'No cabeçalho do menu “Cursos” você encontrará duas abas, a aba “Meus cursos” e a aba “Certificados”.',
        viewYourCertificates: 'Visualize seus certificados',
        viewYourCertificatesDescription:
          'Na aba “Certificados” você poderá visualizar todos os seus certificados de conclusão de módulos e cursos em que está matriculado. Basta filtrar por cursos utilizando “Selecione um curso” e em seguida clicar no botão “Certificado” referente ao módulo que deseja visualizar as informações detalhadas. Ao lado do botão   “Certificado” temos o ícone de uma impressora que possibilita o aluno a imprimir o seu certificado.',
        filterAndCourseSelect: 'Filtros e Seleção de Cursos',
        filterAndCourseSelectDescription:
          'No topo da página, você pode filtrar os cursos disponíveis utilizando o quadro “Buscar”, selecionando a “Área de conhecimento” ou em “Selecionar status”. Se você deixar esses campos em branco, poderá ver todos os cursos e aulas aos quais tem acesso.',
        courseCardsOverview: 'Visão Geral dos Cards dos Cursos',
        courseCardsOverviewDescription: 'Os cursos são apresentados no menu "Cursos" em formato de card, contendo informações úteis sobre cada curso.',
        aboutCourse: 'Sobre o Curso',
        onSelectCourse: 'Ao selecionar um curso, você poderá acessar as informações através de duas abas conforme o andamento do curso.',
        aboutCourseDescription:
          'Se o curso já tiver iniciado, você verá na aba Atualizações uma linha do tempo e por ela você ficará atualizado com as últimas notícias. Você encontrará uma linha do tempo que apresenta atualizações importantes em ordem cronológica para as turmas nas quais está matriculado.',
        aboutCourseTwoDescription: 'Na aba Sobre você encontra as informações e principais indicadores sobre o curso, bem como a lista de módulos logo abaixo. Ao selecionar um módulo você será direcionado para as aulas.',
        accessingAndViewingLessons: 'Acessando e visualizando as aulas',
        whenClickCourseToExpand: 'Ao clicar em uma disciplina para expandi-la',
        accessingAndViewingLessonsDesc:
          'Ao entrar na aula, à sua direita, temos uma coluna com todas as aulas organizadas em disciplinas. Clique em cada uma para expandir ou minimizar as aulas disponíveis nela. Observe os indicadores de comentários ao lado de cada aula. Os destacados na cor laranja são aqueles não lidos. Na parte central da tela, a aula será exibida. Logo abaixo, estarão todos os materiais disponíveis para aquela aula (exercícios, atividades complementares, guias de estudos) assim como os comentários feitos por alunos e professores/mentores.',
        materialsAndForum: 'Materiais e fórum',
        materialsAndForumDescription: 'Acesso a todos os comentários feitos por estudantes, professores e mentores, bem como materiais relacionados à aula, se disponíveis.',
        handouts: 'Apostilas',
        handoutsDescription: 'Acesso a questões relacionadas à aula para que possa responder.',
        complementaryActivity: 'Ativ. complementares',
        complementaryActivityDescription: 'Informações sobre atividades complementares a serem desenvolvidas nesta aula, se disponíveis.',
        payAttentionToTheRequirements: 'Fique atento aos requisitos',
        payAttentionToTheRequirementsDescription:
          'Pode ser que você tenha que cumprir alguns requisitos antes de avançar nos seus cursos. Às vezes você precisa realizar uma prova, realizar uma atividade ou assistir todas as aulas de um módulo para liberar outras aulas.',
        adjustYourTasks: 'Ajuste suas tarefas arrastando-as na grade',
        adjustYourTasksDescription: 'As tarefas podem ser arrastadas na grade semanal, permitindo que você ajuste suas atividades de acordo com seus horários.',
        markYourTasks: 'Marque tarefas como concluídas',
        markYourTasksDescription: 'Para finalizar uma tarefa, clique no círculo no canto superior esquerdo da tarefa, e ela será marcada como concluída.',
        createNewTasks: 'Crie novas tarefas',
        createNewTasksDescription:
          'Clique no símbolo de "+" que aparece no final de cada coluna na tabela semanal para criar uma nova tarefa no dia escolhido. No painel que aparecerá no canto direito da tela, insira informações como título, tipo, duração, descrição e matérias relacionadas à tarefa. Clique em "criar tarefa" para adicionar a tarefa ao calendário no dia selecionado.',
        tasksDetails: 'Detalhes da tarefa',
        tasksDetailsDescription: 'Ao clicar sobre uma tarefa, existe uma opção chamada “mais detalhes” que caso o estudante clique, será direcionado para uma página com mais detalhes da tarefa.',
        quickAccessCourse: 'Acesso rápido aos cursos em andamento',
        quickAccessCourseDescription:
          'Retome seus estudos de onde parou. Aqui você terá acesso rápido aos cursos em andamento. Isso permite que você retome facilmente as aulas e continue seu aprendizado sem perder tempo procurando onde parou. A plataforma foi projetada para tornar a transição entre sessões de estudo o mais suave possível.',
        timeline: 'Atalho para Aulas ao vivo e Plano de estudos',
        timelineDesc: 'Acesse aulas em tempo real e/ou o seu último plano de estudos acessado através dos botões de atalho.',
        monthlyCalendar: 'Calendário mensal',
        monthlyCalendarDesc:
          'Na lateral direita do Dashboard, você encontrará um calendário mensal que exibe os eventos programados. Use-o para acompanhar seus compromissos acadêmicos e clicar nos dias específicos para ver quais eventos estão agendados. Os detalhes dos eventos aparecerão logo abaixo do calendário, facilitando o planejamento e a organização de sua rotina de estudos.',
        choosingChanel: 'Escolhendo o canal',
        choosingChanelDesc:
          'Ao entrar no "Fórum", você pode selecionar a turma com a qual deseja interagir usando a caixa de seleção "Selecione uma turma" localizada no topo da página. Ao não escolher uma turma, sua interação será com todos os estudantes matriculados na mesma instituição de ensino. Se optar por escolher uma turma, apenas os estudantes matriculados nessa turma específica poderão interagir com você.',
        publishingInFeed: 'Publicando no Fórum',
        publishingInFeedDesc: 'Compartilhe ideias e recursos com seus colegas e professores. Para publicar, basta digitar sua mensagem na caixa de texto localizada abaixo do filtro e clicar no ícone de uma seta a azul à direita para enviar.',
        interactingWithPublications: 'Interagindo com publicações',
        interactingWithPublicationsDesc:
          'No menu você pode interagir, curtir, comentar e responder a publicações de outros alunos ou professores, promovendo discussões produtivas e troca de ideias. Isso ajuda a criar um ambiente de aprendizado colaborativo e estimula o engajamento entre os membros.',
        participantsList: 'Lista de participantes',
        participantsListDesc:
          'À direita do menu, você encontrará uma lista de professores e mentores que estão participando da conversa. Essa informação ajuda a identificar os especialistas presentes e facilita o contato com eles para tirar dúvidas ou buscar orientação.',
        testsSimulationsResults: 'Provas e Resultados',
        testsSimulationsResultsDesc:
          'No topo da página, você encontrará os botões "Provas", que vai te direcionar para as avaliações, e "Meus Resultados", que vai te direcionar para ver os resultados das avaliações corrigidas de acordo com o gabarito do professor.',
        exploreCardMockQuestions: 'Explore os cards de simulados',
        exploreCardMockQuestionsDesc:
          'As avaliações aparecerão no centro da tela em formato de card. Cada card contém informações como título da do simulado, status do simulado, taxonomia, data de aplicação e o seguintes botões "Acessar simulado" ou “Gabarito” a depender se você já realizou ou não a prova.',
        testsMockQuestionsBookResults: 'Simulados e Resultados',
        testMockQuestionsBookResultsDesc:
          'No topo da página, você encontrará os botões "Simulados", que vai te direcionar para as avaliações, e "Meus Resultados", que vai te direcionar para ver os resultados das avaliações corrigidas de acordo com o gabarito do professor.',
        filters: 'Filtros',
        filtersDesc: 'Utilize os filtros no topo da página para selecionar um curso, turma ou status e filtrar as avaliações relevantes.',
        exploreCardAvaliations: 'Explore os cards de avaliações',
        exploreCardAvaliationsDesc:
          'As avaliações aparecerão no centro da tela em formato de card. Cada card contém informações como título da prova, status da prova, taxonomia, quantidade de questões, data de aplicação e um botão "Acessar prova", ou “Gabarito” a depender se você já realizou ou não a prova.',
        analyzeYourResults: 'Selecione Exercícios ou Resultado dos exercícios',
        analyzeYourResultsDesc: 'Na aba “Exercícios”, estarão listados todos criados pelo professor e os criados por você.',
        handoutsFilter: 'Filtre por Apostilas',
        handoutsFilterDesc: 'No topo da aba “Apostilas”, use as caixas de seleção para filtrar Apostilas por "Curso", "Turma" ou "Filtro" (público, privado ou meus cadernos).',
        exploreHandoutsCard: 'Explore os cards de Exercícios',
        exploreHandoutsCardDesc:
          'Os exercícios são apresentados no centro da tela em formato de card. Cada um contém informações como título, nível de dificuldade, matérias relacionadas, quantidade de questões, data de publicação e um botão “Acessar”.',
        accessHandout: 'Acesse uma Apostila',
        accessHandoutDesc: 'Clique no botão “Acessar apostila” em um card para acessar a área de resolução de Apostilas.',
        answerQuestions: 'Responda às questões',
        answerQuestionsDesc:
          'Ao acessar os cards você irá para área de resolução. Leia atentamente cada questão e suas alternativas. Clique no botão "Enviar resposta" após selecionar sua opção. Na coluna à direita, você encontrará o acompanhamento de respostas, aproveitamento em percentual, um cronômetro para controlar o tempo de estudo e um botão “Salvar e sair” para salvar seu progresso e sair da área de resolução. Neste ponto, vamos ressaltar que os exercícios estarão disponíveis para nova resolução a partir de meia noite.',
        trackYourPerformance: 'Analise os seus resultados',
        trackYourPerformanceDesc:
          'Você poderá ver alguns indicadores relevantes logo no início, seguido de gráficos mais detalhados de rendimento. São eles: tempo respondendo, questões respondidas, questões inéditas respondidas, índice de aproveitamento, exercícios concluídos, aproveitamento (erros e acertos) e média móvel de tempo respondendo.',
        saveAndLeave: 'Salve e saia',
        saveAndLeaveDesc: 'Clique no botão "Salvar e sair" na coluna à direita para salvar seu progresso e sair da área de resolução.',
        exploreProjectsCard: 'Explore os cards de projetos',
        exploreProjectsCardDesc:
          'Os projetos são apresentados no centro da tela em formato de card. Cada card contém informações como título, status, participantes e um botão "Acessar projeto". É através dele que você tem acesso aos detalhes do projeto. ',
        accessProjectDesc: 'em um card para visualizar detalhes do projeto.',
        navigateProjectDetails: 'Navegue pelos detalhes do projeto',
        navigateProjectDetailsDesc:
          'Ao acessar um projeto, na tela de detalhamento, você encontrará uma linha do tempo com as versões dos documentos que foram enviados ao professor responsável pelo projeto. Clique em cada envio para ver detalhes do arquivo e comentários dos estudantes e professores.',
        sendFile: 'Enviando arquivos',
        sendFileDesc:
          'Se o status do projeto for "Em andamento", você verá um botão "Enviar uma nova versão" no canto superior direito da tela de detalhamento do projeto. Clique neste botão para abrir a janela de envio de nova versão e após selecionar um arquivo, será criada uma nova versão para o professor avaliar e escrever um comentário. A cada arquivo enviado, uma nova versão é gerada.',
        projectApproval: 'Verifique a aprovação do projeto',
        projectApprovalDesc: 'Quando o professor aprovar sua versão final, o status do projeto mudará para "Aprovado". Isso indica que você concluiu o projeto com sucesso e pode prosseguir para outras tarefas ou projetos na plataforma.',
        overviewProfile: 'Visão geral do perfil e conquistas',
        overviewProfileDesc: 'No topo da tela, você encontrará informações sobre seu perfil, incluindo foto, nome e status de ativo ou inativo. Além disso, observe quantas medalhas você acumulou em sua jornada, conquistadas ao concluir módulos.',
        generalMetrics: 'Métricas gerais de desempenho',
        generalMetricsDesc: 'A primeira seção da tela exibe métricas gerais, como cursos e turmas em que você está inserido, a última aula assistida e a data do último acesso à plataforma.',
        engagementMetrics: 'Métricas de engajamento e colaboração',
        engagementMetricsDesc: 'A segunda seção fornece informações sobre suas interações na plataforma, como curtidas, comentários, materiais e aulas acessadas, e publicações feitas.',
        progressModules: 'Cursos em andamento',
        progressModulesDesc: 'A terceira seção mostra o acompanhamento dos cursos, detalhando o nome, quantidade e percentual de conclusão.',
        movingAverage: 'Média móvel comparativa do estudante',
        movingAverageDesc: 'A quarta seção apresenta um gráfico de área que exibe a quantidade de acessos à plataforma por período (última semana, último mês ou últimos 3 meses).',
        tasksSolution: 'Solução de tarefas e Materiais acessado',
        tasksSolutionDesc:
          'A quinta seção à esquerda apresenta um gráfico de barras mostrando a quantidade de tarefas concluídas e não concluídas por período (última semana, último mês ou últimos 3 meses). À direita exibe uma lista de materiais acessados por período (última semana, último mês ou últimos 3 meses), com detalhes de quantidade de acessos e indicadores percentuais de aumento ou diminuição de acesso a cada material.',
        incomeBySubject: 'Rendimento por assunto',
        incomeBySubjectDesc:
          'A sexta e última seção apresenta uma tabela de rendimento por assunto, permitindo que você escolha a matéria, tópicos e subtópicos relacionados para visualizar sua performance. A tabela mostra uma barra de rendimento e um indicador percentual comparativo com o restante dos estudantes por período (última semana, último mês ou últimos 3 meses).',
        accessingAnAuditorium: 'Acessando uma monitoria',
        accessingAnAuditoriumDesc:
          'Ao entrar no menu “Monitoria” você poderá utilizar o filtro “Selecione um curso” para te ajudar a encontrar o que procura. Se não selecionar nada, todos as monitorias disponíveis a você aparecerão na tela em formato de card. Basta escolher uma e clicar no botão “Acessar.',
        monitoringTheMonitor: 'Acompanhando o monitor',
        monitoringTheMonitorDesc:
          'Ao clicar “Acessar”, você encontrará na próxima tela, na parte central, o tema da aula e poderá acompanhar apenas as mensagens do monitor/professor responsável por essa aula. Esses dados serão organizados por data e hora.',
        interactingWithChat: 'Interagindo pelo chat',
        interactingWithChatDesc:
          'Você pode interagir durante a aula clicando no ícone de “balão” na parte central para responder a um comentário do professor/monitor ou pode escrever diretamente no campo de comentários, no chat localizado na lateral direita. Todos os comentários de alunos, inclusive os seus, sempre estarão organizados por data e hora como no chat central.',
        accessFiles: 'Acessando arquivos',
        accessFilesDesc:
          'Sempre que o professor/monitor enviar arquivos durante a aula, eles aparecerão no chat central, onde você poderá acessá-los, mas também ficam disponíveis no botão lateral direito chamado “Arquivos”. Ao clicar, eles estarão organizados por data além disso para facilitar sua busca, você pode utilizar o filtro “Selecione um professor”.',
        analyzingYourIncomeDesc:
          'Logo abaixo, você poderá visualizar o seu rendimento por matérias, assunto e tópico. Essas são as informações coletadas pela plataforma e que geram seus planos de estudo personalizados. No gráfico radar você verá o seu rendimento por matéria, e no menu lateral “Matérias” você poderá selecionar uma única matéria para detalhar sua performance específica.',
        accessStudyPlan: 'Acessando um Plano de Estudos',
        accessStudyPlanDesc: 'No topo da página são apresentados os planos de estudos em formato de card criados exclusivamente para você.',
        navigatingStudyPlan: 'Navegando pelo Plano de Estudos',
        navigatingStudyPlanDesc:
          'Ao clicar em “Acessar” um plano de estudos, você pode navegar nos conteúdos gerados para cada semana. Quando o ícone de uma semana estiver verde, significa que as tarefas já foram concluídas, mas quando estiver vermelho, você ainda tem tarefas a concluir e em cinza são as semanas que ainda não iniciaram. De qualquer forma, poderá acessar todas elas para revisar ou adiantar qualquer conteúdo.',
        studyingStudyPlan: 'Estudando através do Plano de Estudos',
        studyingStudyPlanDesc:
          'Ao selecionar uma semana, os seus blocos de estudo aparecerão no centro da tela. Clique em cada um para acessar. Cada bloco de estudo é composto por um vídeo podendo ou não conter anexos diversos. Após terminar, volte a página anterior e marque como concluído o bloco clicando na bolinha ao lado esquerdo da atividade.',
        profileInformations: 'Informações do Perfil',
        profileInformationsDesc:
          'Dê um toque pessoal ao seu perfil. Ao clicar na aba "Informações do Perfil", você pode editar informações importantes, como seu nome, foto de perfil, e-mail, telefone e uma breve biografia. Essa personalização permite que outros usuários conheçam um pouco mais sobre você e estabeleçam conexões significativas na plataforma. Não se esqueça de clicar no botão "Salvar" após fazer as alterações.',
        notificationsDesc:
          'Na aba "Notificações", você tem controle total sobre como e quando deseja receber atualizações importantes. Você pode ligar ou desligar notificações individuais, garantindo que você receba apenas as informações mais relevantes para sua experiência de aprendizado. Selecione suas preferências de notificação por SMS/WhatsApp ou e-mail e escolha quais eventos você gostaria de ser notificado.',
        eventCreation: 'Criação de eventos',
        addNewMaterials: 'Adição de novos materiais',
        newPosts: 'Novos posts no feed',
        creatingComplementaryActivity: 'Criação de atividades complementares',
        tasksCreation: 'Criação de tarefas',
        addANewProjects: 'Adição a novos projetos',
        security: 'Segurança',
        securityDesc:
          'Na aba "Segurança", você pode alterar sua senha sempre que achar necessário. Proteja sua conta com uma senha forte. A segurança da sua conta é fundamental para proteger suas informações pessoais e garantir uma experiência segura na plataforma. Insira sua senha atual, digite a nova senha desejada, confirme-a e clique em "Salvar" para atualizar sua senha.'
      },
      button: {
        approve: 'Aprovar',
        newModule: 'Novo módulo',
        continue: 'Continuar',
        delete: 'Excluir',
        saveContinue: 'Salvar e continuar',
        save: 'Salvar',
        edit: 'Editar',
        confirm: 'Confirmar',
        view: 'Visualizar',
        sendAnswer: 'Enviar resposta',
        textAnswerCorret: 'Resposta correta. Parabéns!',
        textAnswerIncorrect: 'Não foi dessa vez. Gabarito correto: letra',
        answerKey: 'Gabarito',
        startExam: 'Iniciar prova',
        notFinish: 'Ainda não',
        finish: 'Finalizar',
        nextQuestion: 'Próxima pergunta',
        submitAndFinish: 'Finalizar e enviar',
        access: 'Acessar',
        supportMaterial: 'Material de apoio',
        aboutProject: 'Sobre o projeto',
        back: 'Voltar',
        accessProject: 'Acessar projeto',
        detailsProject: 'Detalhes do projeto',
        yes: 'Sim',
        no: 'Não',
        expand: 'Expandir',
        enter: 'Entrar',
        submit: 'Enviar',
        cancel: 'Cancelar',
        certification: 'Certificado',
        next: 'Próximo',
        previous: 'Anterior',
        manage: 'Gerenciar',
        remove: 'Remover',
        add: 'Adicionar',
        addQuestions: 'Adicionar questões',
        manageQuestionBooks: 'Gerenciar exercícios',
        newSimulation: 'Novo simulado',
        manageSimulations: 'Gerenciar simulados',
        results: 'Resultados',
        manageClasses: 'Gerenciar turmas',
        viewData: 'Ver dados',
        viewMetrics: 'Ver métricas',
        manageContent: 'Gerenciar conteúdo',
        createModule: 'Criar módulo',
        manageModules: 'Gerenciar módulos',
        startCourse: 'Começar curso',
        continueCourse: 'Continuar curso',
        newDiscipline: ' Nova disciplina',
        createDiscipline: 'Criar disciplina',
        createLesson: 'Nova aula',
        offlineExam: 'Prova offline',
        printExam: 'Imprimir prova'
      },
      textsCommon: {
        courses: 'Cursos',
        course: 'Curso',
        teacher: 'Professor',
        participants: 'Participantes',
        teachers: 'professores',
        students: 'Estudantes',
        participantDistribution: 'Distribuição dos participantes',
        projects: 'projetos',
        delete: 'Deletar',
        data: 'Dados',
        lastUpdateAt: 'Última atualização às',
        totalStudents: 'Total de estudantes',
        submissionIndex: 'Índice de envio',
        until: 'Até',
        search: 'Buscar',
        title: 'Título',
        addQuestionBook: 'Adicionar apostila',
        newMaterial: 'Novo material',
        newActivity: 'Nova atividade',
        name: 'Nome',
        titleChooseSubject: 'Escolher assunto',
        matter: 'Matéria',
        matters: 'Matérias',
        topic: 'Tópico',
        questions: 'Questões',
        textSelectMatterToAdd: 'Selecione uma matéria para adicionar tópicos.',
        tagConcluded: 'Concluído',
        messagesTitle: 'Mensagens',
        placeholderSendYourComment: 'Envie seu comentário',
        textPressEnter: 'Pressione enter para enviar.',
        unavailable: 'Indisponível',
        available: 'Disponível',
        availableUntil: 'Disponível até',
        createdAt: 'Criado em',
        buttonAddMatters: 'Adicionar matérias',
        solutionTasks: 'Solução de tarefas',
        accesses: 'Acessos',
        likes: 'Curtidas',
        numberLikes: 'Número de curtidas no feed',
        comment: 'Comentário',
        comments: 'Comentários',
        sendComment: 'Enviar comentário',
        numberComments: 'Número de mensagens enviadas nos fóruns das aulas e nos comentários do feed',
        accessedMaterials: 'Materiais acessados',
        numberAccessedMaterials: 'Número total de materiais acessados',
        accessedClasses: 'Aulas acessadas',
        numberAccessedClasses: 'Número de aulas acessadas',
        numberPosts: 'Número de publicações criadas no feed',
        research: 'Pesquisar',
        module: 'Módulo',
        unit: 'Unidade',
        placeholderTaskDescription: 'Digite a descrição da tarefa',
        image: 'Imagem',
        placeholderUploadImage: 'Clique para fazer upload da imagem de capa',
        linkToMeeting: 'Link para reunião online',
        questionBook: 'Apostila',
        simulations: 'Simulados',
        activities: 'Atividades',
        writeHere: 'Escreva aqui',
        response: 'Resposta',
        dateResponse: 'Data da resposta',
        class: 'Turma',
        courseModules: 'Módulos',
        modules: 'Módulos',
        publishedModules: 'Módulos publicados',
        lessons: 'Aulas',
        published: 'Publicado',
        unpublished: 'Não publicado',
        disciplineName: 'Nome da disciplina',
        mandatoryLesson: 'Aula obrigatória',
        lessonName: 'Nome da aula',
        watchingNow: 'assistindo agora'
      },
      toast: {
        error: 'Ops, houve um erro. Tente novamente',
        errorMedals: 'Erro ao carregar medalhas',
        errorNotification: 'Erro ao buscar notificações.',
        errorNotificationTimeline: 'Erro ao buscar notificações da timeline.',
        errorGetPost: 'Erro ao buscar posts.',
        errorGetClassroom: 'Erro ao buscar turmas do estudante.',
        errorGetTags: 'Erro ao buscar tags.',
        errorGetClasses: 'Erro ao carregar turmas',
        errorGetClassesInfo: 'Erro ao buscar informações da Turma',
        errorGetCourseInfo: 'Erro ao buscar informações do Curso',
        errorGetStudentInfo: 'Erro ao buscar informações do estudante.',
        errorGetCourse: 'Erro ao carregar cursos.',
        errorGetExams: 'Erro ao carregar provas',
        errorGetModule: 'Erro ao carregar módulos',
        errorEditTask: 'Erro ao editar Tarefa.',
        errorCreateComment: 'Erro ao criar comentário.',
        errorGetComment: 'Erro ao buscar comentários.',
        errorDeleteComment: 'Erro ao deletar comentário.',
        errorGetCertifications: 'Falha ao carregar certificados',
        errorSendAnswer: 'Erro ao enviar resposta',
        errorGetCard: 'Erro ao buscar cards.',
        errorGetMetrics: 'Falha ao carregar métricas',
        errorSelectAlternative: 'Selecione a alternativa',
        errorGetActivity: 'Erro ao buscar atividade complementar.',
        errorGetActivityStudent: 'Erro ao buscar atividade do estudante.',
        errorCreateQuestionBook: 'Erro ao criar cadernos de questões.',
        errorDeleteQuestionBook: 'Erro ao deletar caderno de questões.',
        errorGetQuestionBooks: 'Erro ao buscar cadernos de questões.',
        errorGetFilteredQuestion: 'Não existem questões para esse filtro',
        errorGetStudentMetrics: 'Erro ao carregar métricas do estudante',
        errorDeleteProject: 'Erro ao excluir projeto.',
        errorGetProject: 'Erro ao buscar projetos.',
        errorCreateProject: 'Erro ao criar projeto.',
        errorEditProject: 'Erro ao editar projeto.',
        errorUpdatePass: 'Erro ao atualizar senha',
        errorGetNotificationSettings: 'Erro ao buscar configuração de notificações',
        errorUpdateNotificationSettings: 'Erro ao atualizar configuração de notificações',
        errorEnterEmailPass: 'Digite seu e-mail e senha',
        errorCreateAccount: 'Erro ao criar a sua conta',
        errorCreateQuestion: 'Erro ao criar questão',
        errorSaveQuestionBook: 'Erro ao salvar apostila!',
        errorGetSubjects: 'Erro ao buscar matérias!',
        errorGetQuestionBook: 'Erro ao buscar apostila',
        errorAddSubject: 'Erro ao adicionar matéria',
        errorGetTaxonomy: 'Erro ao carregar taxonomias',
        errorSubjectRemoved: 'Erro ao deletar matéria',
        errorReorderingQuestions: 'Erro ao reordenar questões',
        errorCreateSimulation: 'Erro ao criar simulado',
        errorEditSimulation: 'Erro ao editar simulado',
        errorCreateExam: 'Erro ao criar prova',
        errorEditExam: 'Erro ao editar prova',
        errorTryAgain: 'Houve um erro, tente novamente',
        errorGetExam: 'Erro ao buscar avaliação',
        errorGetAgenda: 'Erro ao buscar agenda',
        errorDeleteQuestion: 'Erro ao deletar questão',
        errorEditQuestion: 'Erro ao editar questão',
        errorDeleteCourse: 'Erro ao excluir curso',
        errorDeleteNotes: 'Erro ao excluir anotação',
        errorDeleteClass: 'Erro ao excluir turma',
        errorDeleteProjectGroup: 'Erro ao excluir Grupo de Projetos.',
        errorDeleteDiscipline: 'Erro ao excluir disciplina!',
        errorDeleteModule: 'Erro ao excluir Módulo!',
        errorDeleteParticipants: 'Erro ao excluir participante.',
        errorDeleteSimulation: 'Erro ao deletar simulado',
        errorDeleteMaterial: 'Erro ao deletar material',
        errorDeleteExam: 'Erro ao deletar prova',
        errorDeleteLesson: 'Erro ao deletar aula.',
        errorDeleteQuiz: 'Erro ao deletar Questionário de percepção.',
        errorDeleteActivity: 'Erro ao deletar atividade.',
        errorDeleteBook: 'Erro ao deletar caderno',
        errorDeleteProfile: 'Erro ao deletar perfil',
        errorDeleteVersion: 'Erro ao deletar versão',
        errorDeleteTask: 'Erro ao excluir tarefa',
        errorCreateTask: 'Erro ao criar tarefa',
        errorCreateClass: 'Erro ao criar turma',
        errorCreateCourse: 'Erro ao criar curso',
        errorCreateMaterial: 'Erro ao criar material',
        errorCreateNotes: 'Erro ao criar Anotação',
        errorCreateLesson: 'Erro ao criar aula!',
        errorCreateDiscipline: 'Error ao criar disciplina',
        errorCreateTaxonomy: 'Erro ao criar taxonomia',
        errorCreateEvent: 'Erro ao criar evento',
        errorCreateEventDate: 'Data inválida! O prazo máximo para criar o evento é de um ano a partir da data atual.',
        errorPublishTask: 'Erro ao publicar tarefa',
        errorPublishBook: 'Erro ao publicar apostila',
        errorPublishQuestion: 'Erro ao publicar questão',
        errorPublishExam: 'Erro ao publicar prova',
        errorGetFirstLvlTaxonomy: 'Erro ao buscar taxonomias de primeiro nível.',
        errorGetStudents: 'Erro ao buscar estudantes.',
        errorGetMaterials: 'Erro ao buscar materiais.',
        errorGetUser: 'Erro ao buscar usuário',
        errorGetParticipants: 'Erro ao buscar participantes.',
        errorGetQuestions: 'Error ao buscar questões',
        errorGetSimulations: 'Erro ao buscar Simulados',
        errorGetTaskOverflow: 'Erro ao buscar excesso de tarefas.',
        errorGetDailyTaskOverload: 'Erro ao buscar sobrecarga de tarefas diárias.',
        errorEditCourse: 'Erro ao editar curso.',
        errorGetSubjects: 'Erro ao buscar matérias.',
        errorGetQuiz: 'Erro ao buscar questionário',
        errorEditQuiz: 'Erro ao editar questionário.',
        errorCreateQuiz: 'Erro ao criar questionário',
        errorAddParticipant: 'Erro ao adicionar participante.',
        errorEmailNotFound: 'Não existe um usuário com este e-mail.',
        errorStartFlashCard: 'Erro ao abrir a revisão.',
        errorOnSendAnswerFlashCard: 'Erro ao enviar resposta da revisão.',
        errorFetchingActivity: 'Erro ao buscar atividade',
        errorLoadClass: 'Erro ao carregar aulas',
        errorLoadCourseData: 'Erro ao carregar dados do curso',
        errorReorderSubject: 'Erro ao reordenar disciplinas!',
        errorDeletActivity: 'Erro ao excluir atividade',
        errorFetchComplementActivity: 'Erro ao buscar atividades complementares.',
        errorIncorrectOrMissingValues: 'Valores incorretos ou ausentes',
        errorIncorrectColumns: 'Colunas incorretas',
        errorEmptySpreadsheet: 'Planilha vazia',
        errorCourseDoesNotHaveThisClass: 'Curso não possui esta turma',
        errorCourseDoesNotExist: 'Curso não existe',
        errorInstitutionDoesNotHaveThisClass: 'Instituição não possui esta turma',
        errorInstitutionDoesNotExist: 'Instituição não existe',
        errorRegisterOrGroupedUserFail: 'Falha no registro ou usuário agrupado',
        errorGetDuels: 'Erro ao buscar duelos',
        errorCreateDuel: 'Erro ao criar duelo',
        errorActiveDuel: 'Já existe um duelo ativo com este usuário',
        errorGetDuelsSentRequests: 'Erro ao buscar solicitações de duelos enviadas',
        errorGetDuelsReceivedRequests: 'Erro ao buscar solicitações de duelos recebidas',

        saving: 'Salvando...',
        loadingCreateQuiz: 'Criando questionário...',

        successCreatedDuel: 'Duelo criado com sucesso',
        successActivityDelet: 'Atividade excluida!',
        successRemoveParticipant: 'Sucesso ao remover participante!',
        successAddParticipant: 'Sucesso ao adicionar participante!',
        successCreateQuiz: 'Questionário criado com sucesso!',
        successDeleteMensage: 'Mensagem deletada!',
        successPublish: 'Publicado com sucesso!',
        successUnpublish: 'Despublicado com sucesso!',
        successSimulationDeleted: 'Simulado deletado com sucesso',
        successCreatedClass: 'Turma criada com sucesso',
        successCreateCourse: 'Sucesso ao criar curso!',
        successEditCourse: 'Curso editado!',
        successEditQuestion: 'Questão editada com sucesso!',
        successDeleteCourse: 'Curso excluído com sucesso!',
        successCreateMaterial: 'Sucesso ao criar material!',
        successEditMaterial: 'Sucesso ao editar material!',
        successDeleteMaterial: 'Sucesso ao deletar material!',
        successCreateNotes: 'Anotação criada com sucesso!',
        successDeleteNotes: 'Anotação excluída com sucesso!',
        successDeleteQuiz: 'Questionário excluído com sucesso!',
        successEditQuiz: 'Questionário editado com sucesso!',
        successEditClass: 'Turma editada!',
        successDeleteClass: 'Turma excluída com sucesso.',
        successRegistration: 'Cadastro realizado com sucesso',
        successEditUser: 'Usuário editado com sucesso',
        successRemoveParticipant: 'Sucesso ao remover participante!',
        successMarkQuestionExclusive: 'Questão marcada como exclusiva',
        successMarkQuestionNonExclusive: 'Questão marcada como não exclusiva',
        successEditComplementaryActivity: 'Atividade complementar editada com sucesso.',
        successCreateComplementaryActivity: 'Atividade complementar criada com sucesso.',
        successDeleteActivity: 'Atividade excluída com sucesso!',
        successDeleteEvent: 'Sucesso ao deletar evento!',
        successDeleteExam: 'Prova excluída!',
        successPublishExam: 'Prova publicada!',
        successUnpublishExam: 'Prova despublicada',
        successReorderSubjects: 'Matérias reordenadas com sucesso!',
        successDeleteModule: 'Módulo deletado com sucesso!',
        successAddContent: 'Conteúdo adicionado',
        successSave: 'Salvo com sucesso!',
        successDeleteLesson: 'Sucesso ao deletar aula!',
        successEditLesson: 'Aula editada com sucesso!',
        successCreateLesson: 'Aula criada com sucesso!',
        successEditMaterial: 'Material editado com sucesso!',
        successCreateMaterial: 'Material criado com sucesso!',
        successCreateDiscipline: 'Disciplina criada com sucesso!',
        successRenameDiscipline: 'Disciplina renomeada!',
        successReorderDisciplines: 'Disciplinas reordenadas com sucesso!',
        successReorderLessons: 'Aulas reordenadas com sucesso!',
        successDeleteDiscipline: 'Disciplina excluída com sucesso!',
        successSetLessonMandatory: 'Aula definida como obrigatória',
        successSetLessonNonMandatory: 'Aula definida como não obrigatória',
        successEditDiscipline: 'Sucesso ao editar disciplina!',
        successDeleteParticipant: 'Participante excluído com sucesso!',
        successCreateProfile: 'Perfil criado!',
        successEditProfile: 'Perfil editado!',
        successDeleteProfile: 'Perfil excluído',
        successSaveProfile: 'Perfil salvo!',
        successDeleteQuestionBook: 'Apostila excluída com sucesso!',
        successEditTaxonomy: 'Taxonomia editada com sucesso!',
        successCreateTaxonomy: 'Taxonomia criada com sucesso!',
        successRemoveStudentFromProject: 'Estudante retirado do projeto com sucesso',
        successDeleteProjectGroup: 'Grupo de Projetos deletado com sucesso!',
        successApproveProject: 'Projeto aprovado!',
        successDeleteVersion: 'Versão deletada com sucesso.',
        successEditEvent: 'Evento editado com sucesso!',
        successDeleteQuestion: 'Questão apagada com sucesso!',
        successCreateQuestions: 'Questões criadas com sucesso!',

        successCreateExam: 'Prova criada com sucesso',
        successEditExam: 'Prova editada com sucesso',
        successCreateSimulation: 'Simulado criado com sucesso',
        successEditSimulation: 'Simulado editado com sucesso',
        successReorderingQuestions: 'Questões reordenadas com sucesso!',
        successSubjectRemoved: 'Matéria removida',
        successAddSubject: 'Matéria adicionada!',
        successSaving: 'Salvo com sucesso!',
        successCreateQuestion: 'Questão criada com sucesso',
        sucessEditQuestion: 'Questão editada com sucesso',
        successCreateAccount: 'Conta criada com sucesso!',
        successUpdateNotifications: 'Notificações atualizadas.',
        successUpdatePass: 'Senha alterada com sucesso',
        successUpdateProfile: 'Perfil atualizado!',
        sucessEditProject: 'Projeto editado!',
        successCreateProject: 'Projeto criado!',
        successDeleteProject: 'Projeto excluido!',
        successDeleteComment: 'Comentário excluido com sucesso.',
        successCreateComment: 'Comentário criado com sucesso.',
        successSendMessage: 'Mensagem enviada!',
        successAsk: 'Dúvida enviada com sucesso!',
        successUndoTask: 'A tarefa foi desfeita',
        successCreateQuestionBook: 'Caderno criado com sucesso!',
        successDeleteQuestionBook: 'Apostila deletado com sucesso!',
        successExamDone: 'Avaliação realizada!',
        promiseSendingProject: 'Enviando projeto...',
        successCreatedVersion: 'Versão criada!',
        errorCreatedVersion: 'Erro ao criar versão',
        successSubmitActivity: 'Atividade enviada com sucesso!',
        errorSubmitActivity: 'Erro ao enviar atividade!',
        sucessCreateTask: 'Tarefa criada com sucesso',
        successRemovedTask: 'Tarefa removida com sucesso',
        successEditTask: 'Tarefa editada com sucesso',
        //auditorio
        errorSearchAuditorium: 'Erro ao buscar auditório',
        errorSearchAuditoriums: 'Erro ao buscar monitorias',
        errorSearchMessages: 'Erro ao buscar mensagens',
        errorSearchFiles: 'Erro ao buscar arquivos',
        errorSearchChatMessage: 'Erro ao buscar mensagens do chat',
        errorCreateFile: 'Erro ao criar arquivo:',
        errorCreateMessage: 'Erro ao criar mensagem:',
        errorCreateChatMessage: 'Erro ao criar mensagem no chat',
        errorCreateAuditorium: 'Erro ao criar auditório',
        successCreateAuditorium: 'Auditório criado com sucesso!',
        errorDeleteAuditorium: 'Erro ao deletar auditório',
        successDeleteAuditorium: 'Auditório deletado!',
        successDeleteMessage: 'Mensagem excluída',
        errorEditAuditorium: 'Erro ao editar auditório',
        successUpdateAuditorium: 'Auditório atualizado com sucesso!',
        errorDeleteMessage: 'Erro ao deletar mensagem',
        errorDeleteFile: 'Erro ao deletar arquivos',

        //liveClass
        successCreateLive: 'Aula criada com sucesso!',
        successEditLive: 'Aula editada com sucesso!',
        errorCreateLive: 'Erro ao criar aula.',
        errorEditLive: 'Erro ao editar aula'
      },
      warning: {
        requiredField: 'Campo obrigatório!',
        required: 'obrigatório',
        selectTaxonomy: 'Selecione ao menos uma taxonomia',
        selectSubject: 'Selecione ao menos uma matéria',
        minSubject: 'O mínimo de matérias é 1.',
        minObjective: 'Adicione pelo menos 1 Objetivo',
        warningDeleteSimulation: 'Deseja mesmo excluir esse simulado?',
        warningDeleteInvite: 'Deseja mesmo excluir esse convite?',
        warningDeleteActivity: 'Deseja mesmo excluir esta atividade?',
        warningApproveProject: 'Tem certeza que deseja aprovar o projeto?',
        warningStartExams: 'Você está pronto para começar? Por favor, note que o tempo de resolução para este processo é de {{resolutionTime}} horas contínuas',
        warningStartExams2: 'Uma vez iniciado, é importante entender que este prazo não pode ser pausado',
        warningSendEvaluation: 'Tem certeza que deseja enviar a avaliação para o estudante? Essa ação não poderá ser desfeita.'
      },
      emptyState: {
        titleCertificate: 'Você ainda não tem certificados.',
        textCertificate: 'Finalize módulos para obter um.',
        textNoDataYet: 'Ainda não existem dados aqui. Os dados são criados pelos estudantes.',
        textRegister: 'Ainda não encontramos nenhuma informação para esse período.',
        textInfo: 'Ainda não encontramos nenhuma informação para mostrar aqui.',
        textContent: 'Ainda não temos o que mostrar. O conteúdo poderá aparecer aqui mais tarde.',
        textNotification: 'Você ainda não tem novas notificações.',
        textDate: 'Para começar a ver os dados selecione uma data.',
        textSelect: 'Para começar a ver os dados selecione uma opção.',
        textAdd: 'Adicione à turma participantes que estão na plataforma.',
        textData: 'Ainda não existem dados aqui.',
        textData2: 'Que tal criá-los?',
        textProject: 'Você ainda não está em um projeto.',
        textProcessed: 'Assim que eles estiverem disponíveis, mostraremos aqui para você',
        textDiscipline: 'Você pode estruturar o módulo adicionando disciplinas e em cada disciplina, adicione aulas. Você também pode reordená-las.',
        textDefault: 'Insira um texto.',
        titleNotes: 'Selecione uma anotação.',
        textNotes: 'Para visualizar selecione uma anotação.',
        titleComments: 'Ainda não temos comentários para mostrar.',
        textChildrenClickHere: 'Clique aqui',
        textChildrenToSendComment: 'para enviar um comentário',
        titleViewClassNotes: 'Veja as anotações da sua turma',
        textViewClassNotes: 'ou clique em nova anotação para criar uma.',
        titleImportantNotes: 'Anotações importantes.',
        titleNotFile: 'Ainda não existem arquivos.',
        textNotFile: 'Os arquivos enviados pelo professor serão exibidos aqui.',
        studyPlan: 'Não temos arquivo para mostrar',
        notesTitle: 'Nenhuma anotação.',
        notesText: 'Clique no botão abaixo para começar.',
        notificationTitle: 'Sem Notificações.',
        registerTitle: 'Nenhum registro.',
        eventTitle: 'Sem eventos hoje.',
        eventText: 'Os eventos marcados pelos professores aparecerão aqui.',
        tasksTitle: 'Você está sem tarefas.',
        tasksText: 'As tarefas podem ser criadas por você e seus professores. Clique no botão acima para criar sua primeira tarefa.',
        moduleText: 'Sem módulos no momento.',
        moduleFilteredText: 'Sem módulos com o filtro selecionado.',
        lmsCommentNot: 'Não existem comentários para essa aula.',
        routeNoAccessTitle: 'Você não tem acesso à essa rota.',
        projectNoAccessTitle: 'Ops. Você não tem acesso à esse projeto.',
        contactAnAdministratorText: 'Se acha que isso está errado, contate um Administrador e relate o problema.',
        contactATeacherText: 'Se você acha que isso é um engano, contate um professor.',
        contentNotAvaliable: 'Opa. Este conteúdo não está disponível.',
        materialsNotTitle: 'Sem materiais.',
        materialsNotText: 'Não há materiais disponíveis para você.',
        missingDataTitle: 'Dados Inexistentes.',
        submittingNewVersionText: 'Ainda não há versões para esse projeto. Que tal enviar uma nova versão?',
        inAnyProjectTitle: 'Você não está em nenhum projeto no momento.',
        inAnyProjectText: 'Acha que deveria estar? Contate seu professor para mais detalhes.',
        anyAccessForPeriod: 'Não encontramos nenhum acesso para este período.',
        anyFindedMaterial: 'Nenhum material encontrado.',
        selectTopicText: 'Selecione um tópico.',
        selectSubtopicText: 'Selecione um subtópico.',
        selectOptionTitle: 'Selecione Uma Opção',
        selectOptionRegisterText: 'Selecione uma alternativa ao lado para sua inscrição.',
        selectSubjectPerformanceText: 'Selecione um assunto ao lado para saber detalhes sobre o rendimento.',
        classTitle: 'As aulas não estão disponíveis.',
        classText: 'Assim que os professores cadastrarem novos módulos de aulas, eles vão aparecer aqui para você. E sim, nós vamos te avisar :)',
        subjectSelectText: 'Selecione uma matéria ao lado para exibir as questões.',
        noQuestionsAdd: 'Sem questões adicionadas nessa matéria.',
        noSubjectAdd: 'Sem matérias adicionadas nesta prova.',
        noStudentAnswered: 'Nenhum estudante respondeu esta prova.',
        noAnyResponses: 'Infelizmente não recebemos respostas para essa avaliação.',
        selectCourseToStart: 'Selecione um curso para começar.',
        selectClassToStart: 'Selecione uma turma para começar.',
        selectOption: 'Selecione Uma Opção',
        toStartViewingData: 'Para começar a ver os dados, selecione uma opção.',
        noQuest: 'Sem questionários.',
        questionNotCreated: 'Questões ainda não criadas, que tal cria-las?',
        selectQuestionAnalysis: 'Selecione uma questão para a análise.',
        noProjectFoundFilter: 'Não encontramos nenhum projeto para o filtro selecionado.',
        noActivityFoundFilter: 'Nenhuma atividade para o filtro selecionado.',
        noTaskFoundFilter: 'Não encontramos nenhuma tarefa para esse período.',
        noNewsTitle: 'Sem novidades em sua timeline.',
        noNewsText: 'As atualizações feitas pelos professores aparecerão aqui. Fique atento para não perder nada.',
        noMaterialAccessedText: 'Nenhum material acessado para o filtro selecionado.',
        noActivity: 'Nenhuma atividade complementar encontrada.',
        noMaterialText: 'Nenhum material para o filtro selecionado.',
        noStudentsRegistered: 'Nenhum estudante cadastrado para turma.',
        noVersionsSubmitted: 'Nenhuma versão enviada para esse projeto.',
        noClassCourseSelected: 'Nenhuma turma para o curso selecionado.',
        createNewProjectInteract: 'Crie um novo projeto e interaja com os estudantes.',
        selectClassAnalysis: 'Selecione uma turma para análise.',
        noItemFound: 'Nenhum item encontrado.',
        noStudentStartedModule: 'Nenhum estudante iniciou algum módulo.',
        noAccessFoundForPeriod: 'Não encontramos nenhum acesso para esse período.',
        titleSimulationResultNotAvailable: 'O resultado do seu simulado ainda não está disponível.',
        textSimulationResultNotAvailable: 'Os planos de estudo aparecerão aqui quando o resultado do simulado for publicado!',
        titleNoSimulation: 'Você ainda não realizou nenhum simulado.',
        textNoSimulation: 'Os planos de estudo aparecerão aqui quando uma simulado for realizado.',
        titleSelectDiscipline: 'Selecione uma disciplina.',
        textSelectDiscipline: 'Selecione uma disciplina ao lado para ter acesso ao seu conteúdo.',
        titleSelectModule: 'Selecione um módulo.',
        textSelectModule: 'Selecione um módulo ao lado para ter acesso ao seu conteúdo.',
        titleAdminAuditorium: 'O conteúdo da aula será mostrado aqui.',
        textAdminAuditorium: 'Você pode escrever, enviar arquivos e links para os estudantes usando a barra de interação abaixo.',
        titleStudentAuditorium: 'A aula começará em instantes.',
        textStudentAuditorium: 'Você poderá acompanhar todo o conteúdo por aqui.',
        titleChatAuditorium: 'Chat da aula',
        textChatAuditorium: 'Ainda não foram enviadas mensagens no chat. Escreva um comentário para iniciar a conversa.',
        selectMatterText: 'Selecione uma matéria para ver mais detalhes.',
        achievementsText: 'Você ainda não tem conquistas.',
        challengesText: 'Você ainda não tem desafios.'
      },
      auditorium: {
        auditorium: appName === 'canaleducacao' ? 'Monitoria Enem' : 'Auditório',
        buttonLessonName: 'Inserir nome da aula',
        placeholderWriteMessageHere: 'Escreva sua mensagem aqui.',
        buttonAttachFile: 'Anexar arquivo',
        stepName: appName === 'canaleducacao' ? 'Para começar, digite o nome da monitoria e o nome da aula' : 'Para começar, digite o nome do auditório e o nome da aula',
        stepSchool: 'Adicione os cursos',
        NameNewAuditorium: appName === 'canaleducacao' ? 'Nome da nova monitoria' : 'Nome do novo auditório',
        newAuditorium: appName === 'canaleducacao' ? 'Nova monitoria' : 'Novo auditório',
        minCourses: 'O mínimo de cursos é 1.',
        modalTextsAddCourses: appName === 'canaleducacao' ? 'Adicione os cursos que participarão da monitoria' : 'Adicione os cursos que participarão do auditório',
        studentsInAuditorium: appName === 'canaleducacao' ? 'Estudantes na monitoria' : 'Estudantes no auditório'
      },
      flashCard: {
        review: 'Revisão',
        startReview: 'Iniciar revisão',
        successDescription: 'Você terminou a revisão e finalizou este bloco de estudos. Sempre que precisar rever a matéria, é só voltar aqui :)',
        successTitle: 'É isso ai!',
        successPoints: 'Sua pontuação foi',
        buttonReturnStudyPlan: 'Voltar para o plano de estudos',
        buttonIKnow: 'Sei a respostas ',
        buttonIDontKnow: 'Não sei a resposta',
        basedPreviousAnswer: 'Baseado na minha resposta anterior, eu',
        buttonRight: 'Acertei',
        buttonWrong: 'Errei',
        buttonNextCard: 'Próximo card',
        letReview: 'Vamos revisar!',
        boxDescription: 'Resposta perguntas que vão te ajudar a fixar os assuntos vistos em aula'
      },
      studyPlan: {
        access: 'Bloco de estudos',
        select: 'Selecione um Guia de estudos',
        generate: 'Gerado a partir de',
        studyPlan: 'Plano de estudos',
        studyBlock: 'Bloco de estudos',
        performaceExamsTitle: 'Performance nas provas realizadas',
        week: 'Semana',
        onTrack: '<strong>Parabéns!</strong> Você está em dia com seu plano de estudos',
        completed: 'Bom trabalho! Você finalizou seu plano de estudos',
        unfinished: 'Você tem semanas inacabadas.',
        answerToComplete: 'Responda aos flashcards e conclua o bloco de estudos'
      },
      liveClass: {
        nextClass: 'Próxima aula ao vivo',
        buttonAccess: 'Acessar aula',
        buttonCreate: 'Criar aula ao vivo',
        buttonEdit: 'Editar aula ao vivo',
        aboutTitle: 'Sobre a aula',
        weekOf: 'Semana de',
        lastWeek: 'Semana anterior',
        nextWeek: 'Próxima semana',
        accessClass: 'Acessar a aula',
        titleClass: 'Título da aula',
        placeholderTitle: 'Escreva o título da aula',
        placeholderUrl: 'Insira a URL da aula',
        selectCourse: 'Selecione um curso',
        selectTheCourse: 'Selecione o curso',
        date: 'Data',
        startAndEndTime: 'Horário início e término',
        aboutClass: 'Sobre a aula',
        aboutDescription: 'O que os estudantes precisam saber sobre a aula?'
      },
      filter: {
        selectModule: 'Selecione um módulo.',
        selectClass: 'Selecione uma turma',
        selectRegionals: 'Selecione a regional',
        selectInstitution: 'Selecione a instituição',
        institution: 'Instituição',
        searchHere: 'Pesquise aqui',
        selectTeacher: 'Selecione um professor',
        chooseCourse: 'Escolha um curso',
        chooseClass: 'Escolha uma turma',
        filters: 'Filtros',
        labelSelect: 'Selecione',
        selectSubject: 'Selecione uma matéria',
        labelSelectClassroom: 'Selecione uma turma',
        labelSelectCourse: 'Selecione um curso',
        labelInProgress: 'Em andamento',
        pendingEvaluation: 'Pendente de avaliação',
        labelCompleted: 'Concluído',
        blankLabel: 'Selecione para filtrar',
        labelInitiates: 'Iniciados',
        labelUninitiated: 'Não iniciados',
        labelSelectStatus: 'Selecione um status',
        performed: 'Realizado',
        unrealized: 'Não realizado',
        searchActivity: 'Pesquisar atividade',
        searchMaterial: 'Pesquisar material',
        searchQuestionBook: 'Pesquisar apostila',
        searchProject: 'Pesquisar projeto',
        public: 'Público',
        private: 'Privado',
        published: 'Publicado',
        publishedNot: 'Não publicado',
        myBooks: 'Meus cadernos',
        blankLabelAll: 'Todas',
        blankLabelAll2: 'Todos',
        labelSelectTheClassroom: 'Selecione a turma',
        labelSelectTheCourse: 'Selecione o curso',
        selectQuestionType: 'Selecione o tipo de questão',
        checkboxUnreleasedQuestions: 'Questões inéditas',
        done: 'Realizado',
        unDone: 'Não realizado',
        labelSearchStudent: 'Buscar estudante',
        labelSearchProfile: 'Buscar perfil',
        placeholderStudentName: 'Nome do estudante',
        labelParticipantsWithoutClass: 'Participantes sem turma',
        participant: 'Participante',
        courseName: 'Nome do curso',
        labelSelectArea: 'Selecione uma área',
        labelSearchFiles: 'Pesquisar um arquivo',
        schoolYear: 'Selecione um ano escolar',
        selectTopic: 'Selecione um tema'
      },
      loader: {
        loading: 'Carregando',
        Loading: 'carregando...',
        submitting: 'Enviando...'
      },
      language: {
        language: 'pt'
      }
    }
  }
};

export { messages };
