import { FiStar, FiX } from 'react-icons/fi';
import styles from './styles.module.scss';
import DuelIcon from 'app/components/Icons/DuelIcon';
import { useSession } from 'app/hooks/useSession';
import Avatar from 'app/components/Avatar';

export default function DuelCard({ duel, handleStart, showResults, status }) {
  const { session } = useSession();
  const userAvatar = session.user.image.url;
  const opponentAvatar = duel?.opponent?.image?.url;

  function calculateTimeRemaining(expiration) {
    const now = new Date();
    const expirationTime = new Date(expiration);

    const differenceInMs = expirationTime - now;

    const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differenceInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);

    switch (true) {
      case days === 1:
        return '1 dia';
      case days > 1:
        return `${days} dias`;
      case hours === 1:
        return '1 hora';
      case hours > 1:
        return `${hours} horas`;
      case minutes === 1:
        return '1 minuto';
      case minutes > 1:
        return `${minutes} minutos`;
      default:
        return 'Tempo expirado';
    }
  }

  return (
    <div
      className={`card`}
      style={{ height: 'fit-content' }}
    >
      <div className={`${styles['duel-card']}`}>
        <DuelIcon size={20} />
        <div className={`${styles['duel-card__content']}`}>
          <h2 className={styles['duel-card__title']}>{duel?.['parent-taxonomy']?.name}</h2>
          <span
            className={`badge ${status === 'completed' && styles['duel-card__success-badge']}`}
            style={{ width: 'fit-content' }}
          >
            {duel?.['rounds-completed-by-me'] === 8 && status !== 'completed' ? 'Vez do oponente' : status === 'completed' ? 'Finalizado' : `Você jogou ${duel?.['rounds-completed-by-me']}/8 rodadas`}
          </span>
          <div className={styles['duel-card__main']}>
            <div className={styles['duel-card__main-profile']}>
              <div className={styles['duel-card__main-profile--img']}>
                <Avatar
                  src={duel?.['creator-id'] === session.user.id ? userAvatar : opponentAvatar}
                  className="avatar"
                />
                <FiStar className={styles['duel-card__main-profile--img__star-icon']} />
              </div>
              <span>{duel?.['creator-id'] === session.user.id ? session.user.name : duel?.opponent?.name}</span>
            </div>
            <div className={styles['duel-card__main--score']}>
              <span>{duel?.['creator-id'] === session.user.id ? duel?.['rounds-won-by-me'] : duel?.opponent?.['rounds-won']}</span>
              <FiX className={styles.vs} />
              <span>{duel?.['invited-id'] === session.user.id ? duel?.['rounds-won-by-me'] : duel?.opponent?.['rounds-won']}</span>
            </div>
            <div className={styles['duel-card__main-profile']}>
              <div className={styles['duel-card__main-profile--img']}>
                <Avatar
                  src={duel?.['invited-id'] === session.user.id ? userAvatar : opponentAvatar}
                  className="avatar"
                />
              </div>
              <span>{duel?.['invited-id'] === session.user.id ? session.user.name : duel?.opponent?.name}</span>
            </div>
          </div>
          <span className={calculateTimeRemaining(duel?.['expiration-date']).includes('minuto') ? `${styles['duel-card__deadline--alert']}` : `${styles['duel-card__deadline']}`}>
            O duelo expira em {calculateTimeRemaining(duel?.['expiration-date'])}
          </span>
        </div>
      </div>
      {duel?.['rounds-completed-by-me'] === 8 ? (
        <button
          className="btn btn--outline"
          onClick={() => showResults(duel)}
        >
          Ver resultados
        </button>
      ) : (
        <button
          className="btn btn--outline"
          onClick={() => handleStart(duel)}
        >
          Jogar
        </button>
      )}
    </div>
  );
}
