import React, { useEffect } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { useFormik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { t } from 'i18next';
import { useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import toast from 'react-hot-toast';
import { TableGRE } from './TableGRE';

export default function ViewGRE({ gre, setItems, filter, items }) {
  const requestQuery = gre ? `/regionals/${gre.id}/registration_report` : null;
  const { data, isLoading, error } = useQuery([requestQuery, { filter }]);

  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  });

  const kpis = [
    {
      id: 1,
      title: 'Estudantes cadastrados',
      value: data?.registeredStudents,
      help: 'Número total de estudantes cadastrados de acordo com o filtro selecionado.',
      variation: 'report-kpi-card--check'
    },
    {
      id: 2,
      title: 'Maior número de cadastros',
      value: data?.mostRegistrations?.name,
      help: 'Analise as estratégias da região com maior número de cadastros para identificar práticas bem-sucedidas.',
      variation: 'report-kpi-card--users'
    },
    {
      id: 3,
      title: 'Menor número de cadastros',
      value: data?.leastRegistrations?.name,
      help: 'Analise as estratégias da região com menor número de cadastros para desenvolver ações de melhoria.',
      variation: 'report-kpi-card--user'
    }
  ];

  useEffect(() => {
    if (error) {
      toast.error('Erro ao buscar dados da GRE');
    }
  }, [error]);

  useEffect(() => {
    if (data?.institutions?.length > 0) {
      const parsedItems = data.institutions.map((item, index) => ({ ...item, position: index + 1 }));
      setItems(parsedItems);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <div className="course-about__module fadeIn">
      <div className="report-kpi-card-wrapper u-mb-3">
        {kpis.map(kpi => (
          <div
            className={`report-kpi-card ${kpi.variation}`}
            key={kpi.id}
          >
            {kpi.help && (
              <>
                <FiHelpCircle
                  className="report-kpi-card__help-icon"
                  data-tooltip-id={kpi.id}
                  data-tooltip-content={kpi.help}
                />
                <ReactTooltip
                  id={kpi.id}
                  className="tooltip"
                />
              </>
            )}
            <h3 className="report-kpi-card__title">{kpi.title}</h3>
            <p
              className="report-kpi-card__value"
              title={kpi.value}
            >
              {kpi.value}
            </p>
          </div>
        ))}
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <input
            className="report-form__control"
            aria-label={t('textsCommon.research')}
            placeholder={t('textsCommon.research')}
            type="search"
            name="search"
            id="search"
            onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
          />
        </div>
      </div>

      <div className="round-dg-wrapper">
        <TableGRE
          regionals={items}
          searchValue={filters.values.searchTerm}
        />
      </div>
    </div>
  );
}
