import React, { useEffect, useMemo, useState } from 'react';
import { Link } from '@reach/router';
import defaultImage from 'app/images/placeholders/show-course.jpg';
// import { AvatarStack } from 'app/components/Avatar';
import { PiStudent } from 'react-icons/pi';
import { FiStar, FiUsers, FiChevronDown } from 'react-icons/fi';
import { TbCopy } from 'react-icons/tb';
import Dropdown from 'app/components/Dropdown';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { navigate } from '@reach/router';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { WarningModal } from 'app/components/Modal/WarningModal';
import getPermission from 'app/utils/getPermission';
import promiseRequest from 'app/utils/promiseToast';
import IconVerified from 'app/components/Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';

export default function AdminCourseCard(props) {
  const { course, setFilteredCourses, handleOpenClassroomModal, handleOpenModulesModal } = props;
  const client = useClient();
  const [showWarning, setShowWarning] = useState(false);
  const [showPublishWarning, setShowPublishWarning] = useState(false);

  const { promiseToast, isSubmitting } = promiseRequest();

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteCourse'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteCourse();
      }
    });
  };

  const courseForm = useFormik({
    initialValues: {
      published: course?.published
    }
  });

  useEffect(() => {
    courseForm.setFieldValue('published', course?.published);
  }, [course.published]);

  const deleteCourse = async () => {
    const { error } = await client.delete(['/courses', course.id]);
    if (error) {
      toast.error('Erro ao excluir curso');
    } else {
      toast.success('Curso excluído!');
      setFilteredCourses(prevState => prevState.filter(item => item.id !== course.id));
    }
  };

  const updateCoursePublicity = async values => {
    promiseToast({
      url: `courses/${course.id}`,
      request: {
        published: !values.published
      },
      successText: !courseForm.values.published ? 'Publicado com sucesso!' : 'Despublicado com sucesso!',
      errorText: 'Erro ao publicar curso'
    }).then(() => {
      courseForm.setFieldValue('published', !courseForm.values.published);
      setShowWarning(false);
      setShowPublishWarning(false);
    });
  };

  const handlePublishCourse = () => {
    if (course?.['learning-systems-count'] === 0 && !courseForm.values.published) {
      setShowWarning(true);
    } else if (courseForm.values.published) {
      setShowPublishWarning(true);
    } else {
      updateCoursePublicity(courseForm.values);
    }
  };

  const currentUser = useSession()?.session?.user;
  const hasPermissionToPublish = getPermission('Gerenciar conteúdo', 'Cursos');
  const isCreatorOrAdmin = !course?.['authored-by-user'] || (course?.['authored-by-user'] && currentUser.id === course?.['creator-id']) || currentUser?.profileNames.includes('Administrador');

  return (
    <>
      <div className="admin-course-card">
        <div className="admin-course-card__img-wrapper">
          <img
            src={course?.image?.url || defaultImage}
            alt=""
            className="admin-course-card__img"
          />
          {/* <span className="badge badge--primary">{course?.knowledge}</span> */}
        </div>

        <div className="admin-course-card__inner">
          <div className="admin-course-card__header">
            <div className="admin-course-card--flex-gap-5">
              {course?.['official-content'] && <IconVerified />}
              <h3 className="admin-course-card__title">{course?.title}</h3>
            </div>
            {hasPermissionToPublish && isCreatorOrAdmin && (
              <div
                className="form__check form__switch"
                onClick={e => e.stopPropagation()}
              >
                <input
                  className="form__check-input"
                  id={`publish-${course.id}`}
                  name={`publish-${course.id}`}
                  type="checkbox"
                  checked={courseForm.values.published}
                  onClick={handlePublishCourse}
                />

                <label htmlFor={`publish-${course.id}`}>{t('lms.publish')}</label>
              </div>
            )}
          </div>
          <div className="admin-course-card__body">
            <div className="card__columns">
              {/* <div className="card__count">
                <span className="card__count-label">{t('courses.managers')}</span>
                <FiUsers className="admin-course-card__icon" /> {course?.['manager-count']}
              </div> */}
              <div className="card__count">
                <span className="card__count-label">{t('projects.titleStudents')}</span>
                <PiStudent className="admin-course-card__icon admin-course-card__icon--fill" /> {course?.['student-count']}
              </div>
              <div
                className="card__count card__count--clickable"
                onClick={() => handleOpenModulesModal(course)}
              >
                <span className="card__count-label">{t('textsCommon.publishedModules')}</span>
                <TbCopy className="admin-course-card__icon" /> {course?.['learning-systems-count']}
              </div>
              {/* <div
                className="card__count card__count--clickable"
                onClick={() => handleOpenClassroomModal(course)}
              >
                <span className="card__count-label">{t('courses.classes')}</span>
                <FiUsers className="admin-course-card__icon" /> {course?.['classroom-count']}
              </div> */}
            </div>
          </div>
          <div className="admin-course-card__footer">
            <p className="admin-course-card__rating">
              <FiStar /> {parseFloat(course?.rating).toFixed(1)} <span>({course?.['total-reviews']})</span>
            </p>

            <div
              className="btn-group btn-group--with-dropdown"
              role="group"
              aria-label="Ações"
            >
              <Link
                to={`/curso/admin/${course?.id}`}
                type="button"
                className="btn btn--outline btn--small"
              >
                {t('button.view')}
              </Link>
              {getPermission('Editar cursos', 'Cursos') || getPermission('Excluir cursos', 'Cursos') ? (
                <Dropdown className="btn--small btn btn--outline">
                  <Dropdown.Toggle>
                    <FiChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Content>
                    {isCreatorOrAdmin && getPermission('Editar cursos', 'Cursos') && (
                      <Dropdown.Item
                        onClick={() => {
                          navigate(`/curso/criar/sobre/${course?.id}`);
                        }}
                      >
                        {t('button.edit')}
                      </Dropdown.Item>
                    )}
                    {isCreatorOrAdmin && getPermission('Excluir cursos', 'Cursos') && <Dropdown.Item onClick={handleDelete}>{t('button.delete')}</Dropdown.Item>}
                    <Dropdown.Item to="/metricas/institucional">{t('button.viewMetrics')}</Dropdown.Item>
                    {isCreatorOrAdmin && <Dropdown.Item to={`/curso/admin/gerenciar/${course?.id}/m/disciplinas-e-aulas/d`}>{t('button.manageContent')}</Dropdown.Item>}
                  </Dropdown.Content>
                </Dropdown>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <WarningModal
        isSubmitting={isSubmitting}
        show={showWarning}
        titleFirstButton="Confirmo que quero publicar"
        titleSecondButton="Manter despublicado"
        onClose={() => setShowWarning(false)}
        title={'Você tem certeza que deseja publicar o curso sem módulos e aulas publicados?'}
        description={'Ele aparecerá sem conteúdo pois não há módulos e aulas publicados para os estudantes.'}
        onSubmitFirstButton={() => updateCoursePublicity(courseForm.values)}
        onSubmitSecondButton={() => setShowWarning(false)}
      />

      <WarningModal
        isSubmitting={isSubmitting}
        show={showPublishWarning}
        titleFirstButton="Confirmo que quero despublicar"
        titleSecondButton="Manter publicado"
        onClose={() => setShowPublishWarning(false)}
        title={'Ao despublicar, este conteúdo ficará invisível para os alunos.'}
        description={'Você pode publicá-lo novamente a qualquer momento. Deseja prosseguir com a despublicação?'}
        onSubmitFirstButton={() => updateCoursePublicity(courseForm.values)}
        onSubmitSecondButton={() => setShowPublishWarning(false)}
      />
    </>
  );
}
