import { useEffect } from 'react';
import ActionCable from 'actioncable';
import { useClient } from 'jsonapi-react';

const ActionCableConsumer = ({ channel, channelId, setObjectChannel, channelKey, token }) => {
  const client = useClient();
  const apiBaseUrl = client.config.url;
  let cableUrl = apiBaseUrl.replace(/^http(s)?:\/\/(.*?)\/api\/v1\/?$/, 'wss://$2/cable');
  if (token) {
    cableUrl += `?token=${encodeURIComponent(token)}`;
  }
  const cable = ActionCable.createConsumer(cableUrl);

  // useEffect(() => {
  //   if (!channelKey || (channelKey && channelId)) {
  //     const subscriptionObject = cable.subscriptions.create(
  //       {
  //         channel,
  //         [channelKey]: channelId
  //       },
  //       {
  //         received: data => {
  //           setObjectChannel(data);
  //         }
  //       }
  //     );

  //     return () => {
  //       if (subscriptionObject) {
  //         subscriptionObject.unsubscribe();
  //       }
  //     };
  //   }
  // }, [channel]);

  useEffect(() => {
    if (channel && (!channelKey || (channelKey && channelId))) {
      const subscriptionObject = cable.subscriptions.create(channelKey && channelId ? { channel, [channelKey]: channelId } : { channel }, {
        received: data => {
          setObjectChannel(data);
        }
      });

      return () => {
        if (subscriptionObject) {
          subscriptionObject.unsubscribe();
        }
      };
    }
  }, [channel, channelId, channelKey]);

  return null;
};

export default ActionCableConsumer;
