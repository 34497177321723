import { FiX } from 'react-icons/fi';
import styles from './styles.module.scss';
import { useState } from 'react';
import { FiStar } from 'react-icons/fi';
import { useSession } from 'app/hooks/useSession';
import Avatar from 'app/components/Avatar';
import AcceptModal from '../AcceptModal';

export default function Requests({ type, data, setActiveTab, activeTab, updateDuelsAndRequests }) {
  const { session } = useSession(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [selectedDuel, setSelectedDuel] = useState();

  const userAvatar = session.user.image.url;
  const opponentAvatar = data?.opponent?.image?.url;

  const getBadgeColor = duel => {
    if (type === 'sent' && duel?.status === 'awaiting_acceptance') return 'primary';
    if (type === 'received') {
      if (activeTab === 'active') {
        const timeRemaining = calculateTimeRemaining(duel?.['expiration-date']);
        if (timeRemaining < 3) {
          return 'danger';
        } else {
          return 'warning';
        }
      }
    }
  };

  const getDuelStatus = duel => {
    if (type === 'received') {
      if (activeTab === 'refused') return 'Recusado';
      if (activeTab === 'expired') return 'Expirado';
      return `Expira em ${calculateTimeRemaining(duel?.['expiration-date'])}h`;
    }
    if (duel?.status === 'awaiting_acceptance' && type === 'sent') {
      return 'Aguardando';
    }

    switch (duel?.status) {
      case 'expired':
        return 'Expirado';
      case 'refused':
        return 'Recusado';
    }
  };

  function calculateTimeRemaining(expiration) {
    const now = new Date();
    const expirationTime = new Date(expiration);

    const differenceInMs = expirationTime - now;

    const hours = Math.floor((differenceInMs / (1000 * 60 * 60)) % 24);

    if (hours > 0) {
      return hours;
    }
  }

  const handleAcceptModal = duel => {
    if (type === 'received' && activeTab === 'active') {
      setSelectedDuel(duel);
      setAcceptModal(true);
    }
  };

  return (
    <>
      <div className={`${styles.request} ${type === 'received' && styles['request-received']}`}>
        <div className={styles['request-header']}>
          <span className={styles['request-title']}>Solicitações {type === 'received' ? 'recebidas' : 'enviadas'}</span>
          {type === 'received' && (
            <span className="badge">
              {data.length} {data.length > 1 ? 'novas' : 'nova'}
            </span>
          )}
        </div>
        {type === 'received' && (
          <div className={styles['request-tabs']}>
            <span
              className={`${styles['request-tabs__item']} ${activeTab === 'active' && styles['request-tabs__item--active']}`}
              onClick={() => setActiveTab('active')}
            >
              Ativos
            </span>
            <span
              className={`${styles['request-tabs__item']} ${activeTab === 'refused' && styles['request-tabs__item--active']}`}
              onClick={() => setActiveTab('refused')}
            >
              Recusados
            </span>
            <span
              className={`${styles['request-tabs__item']} ${activeTab === 'expired' && styles['request-tabs__item--active']}`}
              onClick={() => setActiveTab('expired')}
            >
              Expirados
            </span>
          </div>
        )}
        {data.length > 0 ? (
          data.map(item => (
            <div
              className={`${styles['request-duel']} ${type === 'received' && activeTab === 'active' && 'u-cursor-pointer'} `}
              onClick={() => handleAcceptModal(item)}
            >
              <div className={styles['request-duel-players']}>
                <div className={styles['request-duel-players--img']}>
                  <Avatar
                    src={item?.['creator-id'] === session?.user?.id ? userAvatar : opponentAvatar}
                    className={'avatar--xs'}
                  />
                  <FiStar className={styles['request-duel-players--img__star-icon']} />
                </div>
                <FiX />
                <div className={styles['request-duel-players--img']}>
                  <Avatar
                    src={item?.opponent?.id === session?.user?.id ? userAvatar : opponentAvatar}
                    alt="profile img"
                    className="avatar avatar--xs"
                  />
                </div>
              </div>
              <span className={`badge badge--${getBadgeColor(item)}`}>{getDuelStatus(item)}</span>
            </div>
          ))
        ) : type === 'received' ? (
          <span>Não há solicitações com este status</span>
        ) : (
          <span>Nenhuma solicitação enviada</span>
        )}
      </div>

      <AcceptModal
        open={acceptModal}
        setOpen={setAcceptModal}
        duel={selectedDuel}
        updateDuelsAndRequests={updateDuelsAndRequests}
      />
    </>
  );
}
