import React from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Modal from 'app/components/Modal';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';
import Select from 'react-select';
import { useEffect } from 'react';

export default function NewInvitationModal(props) {
  const { show, onClose, classrooms, courses, institutions } = props;
  const client = useClient();

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      institution: 'blank'
    }
  });

  const newInvitationForm = useFormik({
    initialValues: {
      classroom_id: ''
    }
  });

  const handleSubmit = async () => {
    const requestParams = newInvitationForm.values;
    const { error } = await client.mutate('classroom_invitations', requestParams);
    if (error) {
      toast.error('Erro ao criar convite.');
    } else {
      onClose();
      toast.success('Convite criado com sucesso!');
    }
  };

  const filterClassroomByInstitution = classrooms.filter(item => {
    return filters.values.institution === 'blank' || item?.['institution-id'] === parseInt(filters.values.institution);
  });

  const filterClassroomByCourse = filterClassroomByInstitution.filter(item => {
    return filters.values.course === 'blank' || item?.['course-id'] === parseInt(filters.values.course);
  });

  useEffect(() => {
    newInvitationForm.resetForm();
  }, [filters.values]);
  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <form
        method="post"
        className="form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <h2 className="modal__simple-title">{t('classes.accessLink')}</h2>

        <p className="form__description">{t('classes.accessLinkDescription')}</p>

        <div className="form__row">
          <label className="form__label">{t('filter.institution')}:</label>
          <Select
            defaultValue={filters.values.institution}
            openMenuOnFocus
            options={[
              { value: 'blank', label: t('filter.blankLabelAll') },
              ...institutions?.map(item => {
                return { value: item.id, label: item.name };
              })
            ]}
            className="form_select"
            classNamePrefix="react-multi-select"
            placeholder={t('filter.blankLabelAll')}
            noOptionsMessage={() => 'Sem opções'}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            onChange={e => filters.setFieldValue('institution', e.value)}
            style={{ width: '300px' }}
          />
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="course"
          >
            Curso:
          </label>
          <select
            className="form__select"
            name="course"
            id="course"
            onChange={e => filters.setFieldValue('course', e.target.value)}
          >
            <option value="blank">Selecione para filtrar</option>
            {courses.map(t => (
              <option value={t.id}>{t.title}</option>
            ))}
          </select>
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="course"
          >
            {t('classes.associateClass')}
          </label>
          <select
            value={newInvitationForm.values.classroom_id}
            className="form__select"
            name="course"
            id="course"
            onChange={e => newInvitationForm.setFieldValue('classroom_id', e.target.value)}
          >
            <option>{t('createAccount.selectClass')}</option>
            {filterClassroomByCourse?.map(t => (
              <option value={t.id}>{t.title}</option>
            ))}
          </select>
        </div>

        <button
          className="btn btn--wide btn--primary"
          type="submit"
        >
          {t('classes.buttonGenerateLink')}
        </button>
      </form>
    </Modal>
  );
}
