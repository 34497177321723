import { navigate } from '@reach/router';
import { FiClock } from 'react-icons/fi';

export default function GuideCardDashboard({ studyPlan, id }) {
  const clockIconsCount = Math.floor(Math.random() * 2) + 2;
  const clockIcons = Array.from({ length: clockIconsCount }, (_, index) => (
    <FiClock
      key={index}
      className="guide-card__icon"
    />
  ));

  if (!studyPlan) return null;

  return (
    studyPlan && (
      <div
        className="guide-card u-mb-3"
        style={{ borderRadius: 12, padding: 24 }}
        onClick={() => navigate(`/guia-de-estudos/bloco-de-estudo/${id}`)}
      >
        <div
          className="guide-card__content"
          style={{ gap: 0, width: 320 }}
        >
          <p className="guide-card__hat">Guia de Estudos</p>
          <h3
            className="guide-card__title u-text-truncate"
            style={{ fontSize: 20 }}
            title={studyPlan?.title}
          >
            {studyPlan?.title}
          </h3>

          <div
            className="card__badges card__badges--one-line u-mb-0 u-mt-1"
            style={{ lineHeight: 1, alignItems: 'center' }}
          >
            {studyPlan?.taxonomies?.slice(0, 3).map((taxonomy, index) => (
              <span
                className="badge badge--tiny"
                title={taxonomy?.name}
                key={index}
                style={{ marginRight: '8px' }}
              >
                {taxonomy?.name}
              </span>
            ))}

            {studyPlan?.taxonomies?.length > 3 && (
              <span
                className="badge badge--tiny badge--more"
                title={studyPlan?.taxonomies?.slice(3).map(t => ` ${t.name}`)}
              >
                +{studyPlan?.taxonomies?.length - 3}
              </span>
            )}
          </div>
        </div>

        <div className="guide-card__time">{clockIcons}</div>
      </div>
    )
  );
}
