export default function DuelIcon(props) {
  const { size = 16 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.31186 11.6291L8.5545 15.8717M3.45375 14.7198L11.4763 4.50932C11.5797 4.3777 11.7378 4.30086 11.9052 4.30086H15.6711C15.9919 4.30086 16.2434 4.57627 16.2143 4.89569L15.904 8.30928C15.8904 8.45877 15.8158 8.59606 15.6978 8.6888L5.46376 16.7298C5.24665 16.9004 4.93631 16.8818 4.74108 16.6866L3.49696 15.4425C3.30172 15.2472 3.28317 14.9369 3.45375 14.7198Z"
        stroke="#442B8C"
        strokeLinecap="round"
      />
      <path
        d="M5.39567 10.2584L3.29571 8.53293C3.16533 8.43049 3.0892 8.27386 3.0892 8.10804L3.0892 4.37731C3.0892 4.05956 3.36203 3.8104 3.67847 3.83917L7.06015 4.1466C7.19242 4.15862 7.31503 4.21885 7.40518 4.31488C7.4268 4.33791 7.44813 4.36132 7.47197 4.38204L8.63781 5.3952M13.4105 11.9547L15.402 14.4892C15.571 14.7043 15.5526 15.0118 15.3592 15.2052L14.1267 16.4377C13.9333 16.6311 13.6258 16.6495 13.4107 16.4805L10.882 14.4936"
        stroke="#442B8C"
        strokeLinecap="round"
      />
    </svg>
  );
}
