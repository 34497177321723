import { navigate } from '@reach/router';

export default function ShortcutCard({ source, type, title, link, text }) {
  return (
    <div
      className="guide-card-dashboard"
      onClick={() => navigate(link)}
    >
      <div className="guide-card-dashboard__content">
        <div className="guide-card-dashboard__header">
          <p
            className="guide-card-dashboard__hat"
            title={type}
          >
            {type}
          </p>
          <h3
            className="guide-card-dashboard__title"
            title={title}
          >
            {title}
          </h3>
        </div>

        <p className="guide-card-dashboard__text">{text}</p>
      </div>

      <div className="guide-card-dashboard__img-wrapper">
        {type === 'Aula ao vivo' && <div className="guide-card-dashboard__live-dot" />}

        <img
          className="guide-card-dashboard__img"
          src={source}
        />
      </div>
    </div>
  );
}
