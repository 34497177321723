import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Calendar from 'react-calendar';
import { navigate } from '@reach/router';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { FiChevronDown } from 'react-icons/fi';
import bannerEvento from 'app/images/placeholders/banner-evento.png';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import Modal from 'app/components/Modal';
import EventForm from 'app/components/eventForm';
import { useSession } from 'app/hooks/useSession';
import { TeacherDashboardProvider } from './providers/TeacherDashboardProvider';
import Survey from 'app/components/Survey';
import { useSurvey } from 'app/hooks/useSurvey';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import getPermission from 'app/utils/getPermission';
import Dropdown from 'app/components/Dropdown';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ViewEvent(props) {
  return (
    <TeacherDashboardProvider>
      <ScreenContentEvent {...props} />
    </TeacherDashboardProvider>
  );
}

function ScreenContentEvent(props) {
  const { eventId, uri } = props;

  const { t } = useTranslation();
  const client = useClient();
  const [slots, setSlots] = useState([]);
  const [editingEvent, setEditingEvent] = useState();
  const [events, setEvents] = useState([]);
  const [eventDates, setEventDates] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showFormEvent, setShowFormEvent] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { getStudentSurvey, currentStudentSurvey } = useSurvey();
  const [eventData, setEventData] = useState();
  const [eventBanner, setEventBanner] = useState('');
  const { session } = useSession();
  const [loading, setLoading] = useState(true);
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!profiles.includes('Professor')) {
      fetchSlots();
    }
    fetchEvents(selectedDate);
    getStudentSurvey();
  }, []);

  useEffect(() => {
    setEventDates(events.map(i => moment(i['initial-date']).format('YYYY-MM-DD')));
  }, [events]);

  useEffect(() => {
    getEventData();
  }, [props.eventId]);

  const authorityLevel = getAuthorityLevel(session);

  const fetchSlots = async () => {
    try {
      const today = moment().format('YYYY-MM-DD');
      const { data } = await client.fetch(`slots?filter[date_between][initial]=${today}&filter[date_between][final]=${today}`);
      setSlots(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getEventData = async () => {
    setLoading(true);
    try {
      const { data } = await client.fetch(`events/${props.eventId}`);
      setEventData(data);
    } catch (e) {
      console.log(e);
      setNotFound(true);
    }
    setLoading(false);
  };

  const fetchEvents = async initDate => {
    try {
      const initialDate = moment(initDate).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(initDate).endOf('month').format('YYYY-MM-DD');

      const { data } = await client.fetch(`events?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${endDate}`);
      setEvents(data);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmDeleteEvent = event => {
    Swal.fire({
      title: t('projects.warningDeleteEvent'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteEvent(event);
      }
    });
  };

  const deleteEvent = async event => {
    const { error } = await client.delete(['events', event.id]);
    if (!error) {
      window.location.reload(false);
    }
  };

  const editEvent = event => {
    setEditingEvent(event);
    setShowFormEvent(true);
  };

  const closeFormEvent = () => {
    setShowFormEvent(false);
    setEditingEvent(null);
  };

  const onCloseSurvey = () => {
    setShowSurvey(false);
    getStudentSurvey();
  };

  const onActiveStartDateChange = ({ action, activeStartDate, value, view }) => {
    setSelectedDate(activeStartDate);
    fetchEvents(activeStartDate);
  };

  const breadCrumbs = {
    title: 'Início',
    nav: [
      {
        route: '/',
        name: 'Eventos',
        isActive: false
      },
      {
        route: uri,
        name: 'Voltar',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content">
        <BreadCrumbs data={breadCrumbs} />

        <div className="page page--wrap">
          <div className="post-list">
            {loading && <Loader />}

            {!loading && eventData && (
              <div className="card">
                <img
                  className="modal__img-schedule"
                  src={eventData?.image?.url || bannerEvento}
                  alt="banner evento"
                />

                <h2 className="modal__title-schedule">{eventData?.title}</h2>

                <p className="modal__date-schedule">{moment(eventData?.['initial-date']).format('DD/MM/YYYY')}</p>
                <p className="modal__hour-schedule u-mb-3">
                  {eventData?.['initial-time']} - {eventData?.['end-time']}
                </p>

                <div className="u-mb-3">
                  <span className="expanded-calendar__tag expanded-calendar__tag--class">{eventData?.classroom?.title}</span>
                </div>

                <div className="modal__text-schedule">
                  <p>{eventData?.description}</p>
                </div>

                {eventData?.url ? (
                  <div className="form">
                    <div className="form__row">
                      <label htmlFor="linkEvent">Link</label>
                      <div className="form__input-group">
                        <input
                          className="form__control"
                          id="linkEvent"
                          name="linkEvent"
                          type="url"
                          value={eventData?.url}
                          readOnly
                          disabled
                        />
                        <a
                          className="btn btn--primary btn--wide"
                          href={eventData?.url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {t('button.enter')}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {!loading && !eventData && (
              <EmptyState
                type="content"
                title={t('emptyState.contentNotAvaliable')}
                text={t('emptyState.contactATeacherText')}
              />
            )}
          </div>

          <div
            className="page__aside page__aside--calendar"
            style={{ overflow: 'initial' }}
          >
            <div className="react-calendar-wrapper">
              <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                minDetail="month"
                maxDetail="month"
                onActiveStartDateChange={onActiveStartDateChange}
                tileClassName={({ date }) => (eventDates.includes(moment(date).format('YYYY-MM-DD')) ? 'active' : null)}
                locale={t('language.language')}
              />
              {authorityLevel === 'admin' && (
                <button
                  onClick={() => setShowFormEvent(true)}
                  className="btn btn--outline btn--full"
                >
                  {t('events.buttonAddEvent')}
                </button>
              )}

              <div className="sidebar-card-wrapper">
                {events && events.length > 0
                  ? events
                      .filter(i => i['initial-date'] === moment(selectedDate).format('YYYY-MM-DD'))
                      .map((event, index) => (
                        <div
                          key={index}
                          className={eventId === event.id ? 'sidebar-card active' : 'sidebar-card'}
                          onClick={() => navigate(`/evento/${event.id}`)}
                        >
                          {getPermission('Editar eventos', 'Eventos') || getPermission('Excluir eventos', 'Eventos') ? (
                            <Dropdown className="btn btn--outline">
                              <Dropdown.Toggle>
                                <FiChevronDown />
                              </Dropdown.Toggle>
                              <Dropdown.Content>
                                {getPermission('Editar eventos', 'Eventos') && <Dropdown.Item onClick={() => editEvent(event)}>{t('button.edit')}</Dropdown.Item>}
                                {getPermission('Excluir eventos', 'Eventos') && <Dropdown.Item onClick={() => confirmDeleteEvent(event)}>{t('button.delete')}</Dropdown.Item>}
                              </Dropdown.Content>
                            </Dropdown>
                          ) : null}

                          <img
                            className="sidebar-card__img"
                            src={event.image?.url || bannerEvento}
                            alt="banner evento"
                          />
                          <div className="sidebar-card__body">
                            <h3 className="sidebar-card__title">
                              {event['initial-time']} - {event['end-time']}
                            </h3>

                            <p className="sidebar-card__description">{event.title}</p>
                          </div>
                        </div>
                      ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={showFormEvent}
        onClose={closeFormEvent}
      >
        <EventForm
          onClose={() => setShowFormEvent(false)}
          editingEvent={editingEvent}
          events={events}
          setEvents={setEvents}
        />
      </Modal>

      <Modal
        show={showSurvey}
        onClose={() => setShowSurvey(false)}
        contentClassName="modal__content--p-md"
      >
        {currentStudentSurvey && (
          <Survey
            studentSurvey={currentStudentSurvey}
            onClose={onCloseSurvey}
          />
        )}
      </Modal>
    </>
  );
}
