import DuelNotification from 'app/components/DuelNotification';
import { SimpleModal } from 'app/components/Modal';
import styles from './styles.module.scss';
import { FiX } from 'react-icons/fi';
import Avatar from 'app/components/Avatar';
import { useClient } from 'jsonapi-react';
import { useSession } from 'app/hooks/useSession';
import toast from 'react-hot-toast';

export default function AcceptModal({ duel, open, setOpen, updateDuelsAndRequests }) {
  const client = useClient();

  const handleAcceptDuel = async () => {
    const { error } = await client.mutate(`duels/${duel.id}/accept`, {}, { method: 'PUT' });

    if (error) {
      toast.error('Erro ao aceitar duelo');
    }

    setOpen(false);
    updateDuelsAndRequests();
  };

  const handleRefuseDuel = async () => {
    const { error } = await client.mutate(`duels/${duel.id}/refuse`, {}, { method: 'PUT' });

    if (error) {
      toast.error('Erro ao recusar duelo');
    }

    setOpen(false);
    updateDuelsAndRequests();
  };

  return (
    <SimpleModal
      show={open}
      onClose={() => setOpen(false)}
      hideCloseButton
      contentClassName={'u-p-0 simple-modal__content--sm'}
    >
      <div className={`${styles['duel-notification']} ${true && styles['duel-notification--visible']} `}>
        <FiX onClick={() => setOpen(false)} />
        <div className={styles['duel-notification__content']}>
          <Avatar
            src={duel?.opponent?.image?.url}
            className={`avatar avatar--md`}
          />
          <div>
            <span className={styles['duel-notification__content-name']}>{duel?.opponent?.name}</span>
            <p className="u-mb-0">te desafiou para um duelo</p>
            <p className="u-mb-0">Responda antes que o convite expire</p>
            <div className={styles['duel-notification__content-buttons']}>
              <button
                className="btn btn--outline"
                onClick={handleAcceptDuel}
              >
                Aceitar
              </button>
              <span onClick={handleRefuseDuel}>Recusar</span>
            </div>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
}
