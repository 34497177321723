import React, { useEffect, useState } from 'react';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import CountdownTimer from 'app/components/ChallengeCard/ChallengeModal/CountdownTimer';
import DuelQuestion from './DuelQuestion';

export default function Question() {
  const { currentRound, amountRounds, currentChallenge } = useWeeklyChallenge();

  return (
    <section className="challenge-modal__section challenge-modal__section--question">
      <div className="challenge-modal__header">
        <div className="challenge-modal__header-content">
          <h3 className="challenge-modal__title">{currentChallenge?.['parent-taxonomy']?.name}</h3>
          <p className="challenge-modal__subtitle">Rodada {currentRound}</p>
        </div>
        <CountdownTimer className="challenge-modal__countdown-timer" />
      </div>

      <DuelQuestion />
    </section>
  );
}
